
/**
 * 定数を管理するファイル
 */
export class CommonConstants {

  // メールアドレスマッチパタン
  public static readonly MAIL_PATTERN = '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*' +
    '@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?';
  // 拠点ID　MSI
  public static readonly BASE_ID_MSI: number = 1;

  // 拠点区分
  public static readonly BASE_TYPE_ADMIN: number = 0;
  public static readonly BASE_TYPE_COMPANY: number = 1;
  public static readonly BASE_TYPE_DEPART: number = 2;
  public static readonly BASE_TYPE_PLACE_SELF: number = 3;
  public static readonly BASE_TYPE_PLACE_OTHER: number = 9;

  public static readonly BASE_TYPE_NAME_COMPANY: string = '葬儀社';
  public static readonly BASE_TYPE_NAME_DEPART: string = '部門';
  public static readonly BASE_TYPE_NAME_PLACE_SELF: string = '自営式場';
  public static readonly BASE_TYPE_NAME_PLACE_OTHER: string = '他営式場';

  // 税計算区分
  public static readonly CALC_TYPE_FLOOR: number = 1;
  public static readonly CALC_TYPE_CEIL: number = 2;
  public static readonly CALC_TYPE_ROUND: number = 3;

  // 日程ID
  public static readonly SCHEDULE_ID_TSUYA: number = 1;
  public static readonly SCHEDULE_ID_KOKUBETSU: number = 2;

  // サービスID
  public static readonly SERVICE_ID_CHOBUN: number = 10;
  public static readonly SERVICE_ID_KUMOTSU: number = 20;
  public static readonly SERVICE_ID_KODEN: number = 30;
  public static readonly SERVICE_ID_KODEN_COMMISSION: number = 31;
  public static readonly SERVICE_ID_HENREIHIN: number = 40;
  public static readonly SERVICE_ID_HENREIHIN_KUMOTSU: number = 41;
  public static readonly SERVICE_ID_HENREIHIN_KODEN: number = 42;
  public static readonly SERVICE_ID_MESSAGE: number = 50;

  // サービス受付期限単位
  public static readonly SERVICE_RECEPTION_UNIT_DAY: number = 1;
  public static readonly SERVICE_RECEPTION_UNIT_HOUR: number = 2;

  // 時間単位
  public static readonly TERM_UNIT_DAY: number = 1;
  public static readonly TERM_UNIT_MONTH: number = 2;
  public static readonly TERM_UNIT_YEAR: number = 3;

  // クレジットカード産業識別コード
  public static readonly CARD_INDUSTRIAL_VISA: string = '4';
  public static readonly CARD_INDUSTRIAL_MASTER: string = '5';

  // エプシロン取引区分
  public static readonly EPSILON_CONTRACT_TYPE_BASIC: number = 1;
  public static readonly EPSILON_CONTRACT_TYPE_KODEN: number = 2;

  // 注文ステータス
  public static readonly ORDER_STATUS_ID_NONE: number = 0;
  public static readonly ORDER_STATUS_ID_UNORDER: number = 1;
  public static readonly ORDER_STATUS_ID_ORDERED: number = 2;
  public static readonly ORDER_STATUS_ID_CONFIRMED: number = 3;

  public static readonly ORDER_STATUS_NONE: string = '未作成';
  public static readonly ORDER_STATUS_UNORDER: string = '未発注';
  public static readonly ORDER_STATUS_ORDERED: string = '発注済';
  public static readonly ORDER_STATUS_CONFIRMED: string = '確認済';

  // 公開ステータス
  public static readonly RELEASE_STATUS_ID_NONE: number = 0;
  public static readonly RELEASE_STATUS_ID_OK: number = 1;
  public static readonly RELEASE_STATUS_ID_NG: number = 2;

  public static readonly RELEASE_STATUS_NONE: string = '未確認';
  public static readonly RELEASE_STATUS_OK: string = '公開OK';
  public static readonly RELEASE_STATUS_NG: string = '公開NG';

  // 選択ステータス
  public static readonly SELECT_STATUS_ID_NONE: number = 0;
  public static readonly SELECT_STATUS_ID_SELECTING: number = 1;
  public static readonly SELECT_STATUS_ID_CONFIRM: number = 2;

  // 提案ステータス
  public static readonly PROPOSAL_STATUS_ID_NONE: number = 0;
  public static readonly PROPOSAL_STATUS_ID_PROPOSED: number = 1;

  // AF受注テータス
  public static readonly AF_ORDER_STATUS_ID_NEGOTIATING: number = 1;
  public static readonly AF_ORDER_STATUS_ID_ORDERED: number = 2;
  public static readonly AF_ORDER_STATUS_ID_FAILURED: number = 3;
  public static readonly AF_ORDER_STATUS_NEGOTIATING: string = '商談中';
  public static readonly AF_ORDER_STATUS_ORDERED: string = '受注';
  public static readonly AF_ORDER_STATUS_FAILURED: string = '失注';

  // AF受注見込みステータス
  public static readonly AF_ORDER_CHANCE_STATUS_ID_NO_PROSPECT: number = 0;
  public static readonly AF_ORDER_CHANCE_STATUS_ID_PROSPECTIVE: number = 1;
}

export class Calendar {

  static readonly DAYS = ['日', '月', '火', '水', '木', '金', '土'];
  static readonly MONTHS = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
  static readonly MONTHS_SHORT = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
  public static readonly JA_TEXT = {
    days: Calendar.DAYS,
    months: Calendar.MONTHS,
    monthsShort: Calendar.MONTHS_SHORT,
    today: '今日',
    now: '現在',
    am: 'AM',
    pm: 'PM'
  };
  public static readonly JA_TEXT_MONTH = {
    days: Calendar.DAYS,
    months: Calendar.MONTHS,
    monthsShort: Calendar.MONTHS_SHORT,
    now: '今月',
  };
  public static readonly FOMATTER = {
    monthHeader: function (date, settings) {
      return date.getFullYear() + '年';
    },
    dayHeader: function (date, settings) {
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return year + '年' + month + '月';
    },
    date: function (date, settings) {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      if (settings.type === 'month') {
        return year + '/' + month;
      }
      return year + '/' + month + '/' + day;
    }
  };
  public static readonly DATETIME = {
    type: 'datetime',
    firstDayOfWeek: 1,
    today: true,
    text: Calendar.JA_TEXT,
    formatter: Calendar.FOMATTER,
    minDate: new Date('1900/01/01'),
    on: 'click',
    ampm: false
  };
  public static readonly DATE = {
    type: 'date',
    firstDayOfWeek: 1,
    today: true,
    text: Calendar.JA_TEXT,
    formatter: Calendar.FOMATTER,
    minDate: new Date('1900/01/01'),
    on: 'click'
  };
  public static readonly TIME = {
    type: 'time',
    ampm: false,
    on: 'click'
  };
}


class AppView {
  public type: number;
  public tag: string;
  public title: string;
  public description: string;
}

export class AppViewConstants {
  public static readonly 'foc': AppView = {
    type: 1,
    tag: 'app-com-login',
    title: 'FOC | ログイン',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/top': AppView = {
    type: 1,
    tag: 'app-com-top',
    title: 'FOC | トップ',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/login': AppView = {
    type: 1,
    tag: 'app-com-login',
    title: 'FOC | ログイン',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/seko': AppView = {
    type: 1,
    tag: 'app-com-seko',
    title: 'FOC | 施行一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/seko/edit': AppView = {
    type: 1,
    tag: 'app-com-seko-edit',
    title: 'FOC | 施行登録',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/seko/edit/:id': AppView = {
    type: 1,
    tag: 'app-com-seko-edit',
    title: 'FOC | 施行編集',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/base': AppView = {
    type: 1,
    tag: 'app-com-base',
    title: 'FOC | 拠点',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/supplier': AppView = {
    type: 1,
    tag: 'app-com-supplier',
    title: 'FOC | 発注先',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/fuho-sample': AppView = {
    type: 1,
    tag: 'app-com-fuho-sample',
    title: 'FOC | 訃報文',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/chobun-daishi': AppView = {
    type: 1,
    tag: 'app-com-chobun-daishi',
    title: 'FOC | 弔文台紙',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/staff': AppView = {
    type: 1,
    tag: 'app-com-staff',
    title: 'FOC | 担当者',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/item-service': AppView = {
    type: 1,
    tag: 'app-com-item-service',
    title: 'FOC | サービス・商品',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/chobun': AppView = {
    type: 1,
    tag: 'app-com-chobun',
    title: 'FOC | 弔文受付一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/kumotsu': AppView = {
    type: 1,
    tag: 'app-com-kumotsu',
    title: 'FOC | 供花・供物受付一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/koden': AppView = {
    type: 1,
    tag: 'app-com-koden',
    title: 'FOC | 香典受付一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/message': AppView = {
    type: 1,
    tag: 'app-com-message',
    title: 'FOC | 追悼メッセージ受付一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/henreihin': AppView = {
    type: 1,
    tag: 'app-com-henreihin',
    title: 'FOC | 返礼品受付一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/henreihin/order': AppView = {
    type: 1,
    tag: 'app-com-order-henreihin',
    title: 'FOC | 返礼品発注一覧',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/monthly/summary': AppView = {
    type: 1,
    tag: 'app-com-monthly-summary',
    title: 'FOC | 月次集計',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/monthly/confirm': AppView = {
    type: 1,
    tag: 'app-com-monthly-confirm',
    title: 'FOC | 月次確定',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/sales/summary': AppView = {
    type: 1,
    tag: 'app-com-sales-summary',
    title: 'FOC | 売上集計表出力',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/invoice': AppView = {
    type: 1,
    tag: 'app-com-invoice',
    title: 'FOC | 請求書出力',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/af/group': AppView = {
    type: 1,
    tag: 'app-com-af-group',
    title: 'FOC | AF項目グループ',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/af/contact': AppView = {
    type: 1,
    tag: 'app-com-af-contact',
    title: 'FOC | AF連絡方法',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/master': AppView = {
    type: 1,
    tag: 'app-com-hoyo-master',
    title: 'FOC | 法要マスタ',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/sample': AppView = {
    type: 1,
    tag: 'app-com-hoyo-sample',
    title: 'FOC | 法要案内文',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/mail-template': AppView = {
    type: 1,
    tag: 'app-com-hoyo-mail-template',
    title: 'FOC | 法要メールテンプレートhouyoume-rutenpure-to',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/seko/af': AppView = {
    type: 1,
    tag: 'app-com-seko-af',
    title: 'FOC | AF問い合わせ',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/guidance': AppView = {
    type: 1,
    tag: 'app-com-hoyo-guidance',
    title: 'FOC | 法要案内履歴',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/filter/ki': AppView = {
    type: 1,
    tag: 'app-com-hoyo-filter-ki',
    title: 'FOC | 法要案内抽出（忌法要）',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/hoyo/filter/bon': AppView = {
    type: 1,
    tag: 'app-com-hoyo-filter-bon',
    title: 'FOC | 法要案内抽出（盆）',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/event/guidance': AppView = {
    type: 1,
    tag: 'app-com-event-guidance',
    title: 'FOC | イベント案内履歴',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/event/filter': AppView = {
    type: 1,
    tag: 'app-com-event-filter',
    title: 'FOC | イベント案内抽出',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/faq': AppView = {
    type: 1,
    tag: 'app-com-faq',
    title: 'FOC | FAQ',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/ad': AppView = {
    type: 1,
    tag: 'app-com-advertise',
    title: 'FOC | 広告',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/soke-menu': AppView = {
    type: 1,
    tag: 'app-com-soke-menu',
    title: 'FOC | 葬家専用ページメニュー',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'foc/koden/summary': AppView = {
    type: 1,
    tag: 'app-com-koden-summary',
    title: 'FOC | 香典集計',
    description: 'FOC 葬儀オンラインシステム',
  };
  public static readonly 'soke-approve/:id': AppView = {
    type: 2,
    tag: 'app-fam-soke-approve',
    title: 'てれ葬儀 | 葬家ページ公開承認',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke': AppView = {
    type: 2,
    tag: 'app-fam-login',
    title: 'てれ葬儀 | 葬家ログイン',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/login': AppView = {
    type: 2,
    tag: 'app-fam-login',
    title: 'てれ葬儀 | 葬家ログイン',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/seko': AppView = {
    type: 2,
    tag: 'app-fam-seko',
    title: 'てれ葬儀 | 施行情報',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/homei': AppView = {
    type: 2,
    tag: 'app-fam-homei',
    title: 'てれ葬儀 | 芳名・香典帳',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/henrei': AppView = {
    type: 2,
    tag: 'app-fam-henrei',
    title: 'てれ葬儀 | 返礼品',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/info': AppView = {
    type: 2,
    tag: 'app-fam-info',
    title: 'てれ葬儀 | 登録内容変更',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/change/pass': AppView = {
    type: 2,
    tag: 'app-fam-change-password',
    title: 'てれ葬儀 | パスワード変更',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/memorial': AppView = {
    type: 2,
    tag: 'app-fam-memorial',
    title: 'てれ葬儀 | メモリアルアルバム',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/hoyo': AppView = {
    type: 2,
    tag: 'app-fam-hoyo',
    title: 'てれ葬儀 | 法要情報',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/after-follow': AppView = {
    type: 2,
    tag: 'app-fam-seko-af',
    title: 'てれ葬儀 | ご相談窓口',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/inquiry': AppView = {
    type: 2,
    tag: 'app-fam-inquiry',
    title: 'てれ葬儀 | お問合せ',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/faq': AppView = {
    type: 2,
    tag: 'app-fam-faq',
    title: 'てれ葬儀 | よくあるご質問',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'soke/userpolicy': AppView = {
    type: 2,
    tag: 'app-fam-soke-userpolicy',
    title: 'てれ葬儀 | ご利用規約',
    description: 'てれ葬儀 こころ',
  };
  public static readonly '': AppView = {
    type: 3,
    tag: 'app-cus-huho',
    title: 'てれ葬儀',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'fuho': AppView = {
    type: 3,
    tag: 'app-cus-huho',
    title: 'てれ葬儀 | 訃報',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'fuho/:id': AppView = {
    type: 3,
    tag: 'app-cus-huho',
    title: 'てれ葬儀 | 訃報',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'hoyo': AppView = {
    type: 3,
    tag: 'app-cus-hoyo',
    title: 'てれ葬儀 | 法要案内',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'hoyo/:id': AppView = {
    type: 3,
    tag: 'app-cus-hoyo',
    title: 'てれ葬儀 | 法要案内',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'fuho-approve/:id': AppView = {
    type: 3,
    tag: 'app-cus-huho-approve',
    title: 'てれ葬儀 | 訃報承認',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'sitepolicy': AppView = {
    type: 3,
    tag: 'app-cus-sitepolicy',
    title: 'てれ葬儀 | このサイトについて',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'privacypolicy': AppView = {
    type: 3,
    tag: 'app-cus-privacy-policy',
    title: 'てれ葬儀 | プライバシーポリシー',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'userpolicy': AppView = {
    type: 3,
    tag: 'app-cus-userpolicy',
    title: 'てれ葬儀 | ご利用規約',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'tokushoho': AppView = {
    type: 3,
    tag: 'app-cus-tokushoho',
    title: 'てれ葬儀 | 特商法表記',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'howtoshare': AppView = {
    type: 3,
    tag: 'app-cus-howto-share',
    title: 'てれ葬儀 | 訃報共有方法',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'chobun/entry1': AppView = {
    type: 3,
    tag: 'app-cus-chobun1',
    title: 'てれ葬儀 | 弔文申込',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'chobun/entry2': AppView = {
    type: 3,
    tag: 'app-cus-chobun2',
    title: 'てれ葬儀 | 弔文申込',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'kumotsu/entry': AppView = {
    type: 3,
    tag: 'app-cus-kumotsu',
    title: 'てれ葬儀 | 供花・供物申込',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'koden/entry1': AppView = {
    type: 3,
    tag: 'app-cus-koden1',
    title: 'てれ葬儀 | 香典預かり',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'koden/entry2': AppView = {
    type: 3,
    tag: 'app-cus-koden2',
    title: 'てれ葬儀 | 香典預かり',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'message/entry': AppView = {
    type: 3,
    tag: 'app-cus-message',
    title: 'てれ葬儀 | 追悼メッセージ',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'cart': AppView = {
    type: 3,
    tag: 'app-cus-cart',
    title: 'てれ葬儀 | 申込一覧',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'order/entry': AppView = {
    type: 3,
    tag: 'app-cus-order-entry',
    title: 'てれ葬儀 | 申込者情報登録',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'order/confirm': AppView = {
    type: 3,
    tag: 'app-cus-order-confirm',
    title: 'てれ葬儀 | 申込内容確認',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'order/payment': AppView = {
    type: 3,
    tag: 'app-cus-order-payment',
    title: 'てれ葬儀 | 支払い手続き',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'order/complete': AppView = {
    type: 3,
    tag: 'app-cus-order-complete',
    title: 'てれ葬儀 | 申込完了',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'order/:id/receipt': AppView = {
    type: 3,
    tag: 'app-cus-receipt',
    title: 'てれ葬儀 | 領収書',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'inquiry': AppView = {
    type: 3,
    tag: 'app-cus-inquiry',
    title: 'てれ葬儀 | お問合せ',
    description: 'てれ葬儀 こころ',
  };
  public static readonly 'inquiry/funeral': AppView = {
    type: 3,
    tag: 'app-cus-inquiry',
    title: 'てれ葬儀 | お問合せ',
    description: 'てれ葬儀 こころ',
  };
}

