import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { formatDate } from '@angular/common';
import { CdkDragDrop, CdkDragEnter, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { Calendar } from 'src/app/const/const';
import { CalendarOptionsDate, CalendarOptionsDateTime } from 'src/app/components/com-calendar/com-calendar.component';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { ComCalendarComponent } from 'src/app/components/com-calendar/com-calendar.component';
import { BaseGetResponse } from 'src/app/interfaces/base';
import {
  SekoGetResponse, SekoPostRequest,
  SekoScheduleForGet, SekoScheduleForPost, SekoHall,
  MoshuForGet, MoshuForPost,
  KojinForGet, KojinForPost,
  VideoForGet, VideoForPost,
  AlbumPostRequest, AlbumPutRequest, FuhoMailPostRequest,
  ServiceForPost, HenreihinKakegamiForGet, HenreihinKakegamiForPost,
  ServiceForGet, ItemForPost, ItemForGet, HoyoGetResponse, HoyoPostRequest,
  HoyoScheduleForGet, HoyoScheduleForPost, KojinPutRequest,
  SekoContactForGet, SekoContactForPost, NoticeAttachedFilePostRequest,
} from 'src/app/interfaces/seko';
import { Utils } from 'src/app/const/func-util';
import { ScheduleResponse, AddressGetRequest } from 'src/app/interfaces/master';
import { ServiceReceptionTermGetRequest } from 'src/app/interfaces/service';
import { CompanyFuhoSampleGetRequest } from 'src/app/interfaces/fuho-sample';
import { ItemGetRequest, ItemPostRequest } from 'src/app/interfaces/item';
import { CommonConstants } from 'src/app/const/const';
import { HoyoSampleGetRequest } from 'src/app/interfaces/hoyo';
import { StaffGetResponse } from 'src/app/interfaces/staff';
import { AfActivityForGet, AfActivityForPost, AfWishForGet, AfWishForPost } from 'src/app/interfaces/af';
import { isNullOrUndefined } from 'util';

declare var $;
@Component({
  selector: 'app-com-seko-edit',
  templateUrl: './seko-edit.component.html',
  styleUrls: ['./seko-edit.component.scss']
})
export class SekoEditComponent implements OnInit {
  @ViewChild('imageFileUpload', { static: false }) imageFileUpload: ElementRef;
  @ViewChild('invoiceFileUpload', { static: false }) invoiceFileUpload: ElementRef;
  @ViewChild('hallComboEm2', { static: false }) hallComboEm2: ComDropdownComponent;
  @ViewChild('sekoBaseComboEm', { static: false }) sekoBaseComboEm: ComDropdownComponent;
  @ViewChild('contactComboEm', { static: false }) contactComboEm: ComDropdownComponent;
  @ViewChild('scheduleDateEm', { static: false }) scheduleDateEm: ComCalendarComponent;
  @ViewChild('siteEndDateEm', { static: false }) siteEndDateEm: ComCalendarComponent;

  Config = Calendar;
  Const = CommonConstants;
  Utils = Utils;

  seko_id: number = null;
  seko_info: SekoGetResponse = null;
  login_info = this.sessionSvc.get('staff_login_info');
  login_company = Utils.getLoginCompany();
  staff_list = null;
  relation_list = this.sessionSvc.get('relation_list');
  seko_style_list = this.sessionSvc.get('seko_style_list');
  schedule_list = this.sessionSvc.get('schedule_list');
  henrei_param = this.sessionSvc.get('henrei_param');
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();
  calendarOptionDateTime: CalendarOptionsDateTime = new CalendarOptionsDateTime();
  birthday_config: CalendarOptionsDate = new CalendarOptionsDate();
  death_date_config: CalendarOptionsDate = new CalendarOptionsDate();

  fuho_sample_list = null;
  hoyo_sample_list = null;

  message = null;
  message_sub = null;
  err_tab = null;

  // relationCombo = { values: [], clearable: true, allowAdditions: true, showOnFocus: false, forceSelection: false };
  staffCombo = { values: [] };
  allBaseCombo = { values: [] };
  sekoBaseCombo = { values: [] };
  hallCombo = { values: [], allowAdditions: true, forceSelection: false };
  selfHallCombo1 = { values: [] };
  selfHallCombo2 = { values: [], allowAdditions: true, forceSelection: false };
  sekoStyleCombo = { values: [] };
  rateCombo = { values: [{ name: '1', value: 1 }, { name: '2', value: 2 }, { name: '3', value: 3 }] };
  afStatusCombo = {
    values: [
      { name: CommonConstants.AF_ORDER_STATUS_NEGOTIATING, value: CommonConstants.AF_ORDER_STATUS_ID_NEGOTIATING },
      { name: CommonConstants.AF_ORDER_STATUS_ORDERED, value: CommonConstants.AF_ORDER_STATUS_ID_ORDERED },
      { name: CommonConstants.AF_ORDER_STATUS_FAILURED, value: CommonConstants.AF_ORDER_STATUS_ID_FAILURED }
    ], clearable: true, disableSearch: true
  };
  afChanceCombo = {
    values: [
      { name: '◯', value: CommonConstants.AF_ORDER_CHANCE_STATUS_ID_PROSPECTIVE },
      { name: '×', value: CommonConstants.AF_ORDER_CHANCE_STATUS_ID_NO_PROSPECT }
    ], clearable: true, disableSearch: true
  };
  moshuDisplayPlaceCombo = {
    values: [
      { name: '先頭', value: 0 },
      { name: 'ラベル1の下', value: 1 },
      { name: 'ラベル2の下', value: 2 },
      { name: 'ラベル3の下', value: 3 },
      { name: 'ラベル4の下', value: 4 }
    ]
  };

  kojin_edit: KojinForGet = new KojinForGet();
  video_edit: VideoForGet = new VideoForGet();
  video_edit_index = -1;

  service_list = null;
  service_reception_term_list = null;

  albumItems: Array<any> = null;
  albumItemsTable: Array<any[]> = new Array();

  albumImgWidth = 205;
  albumColumnSize = 0;
  albumSeletedItem = null;
  albumApiError = false;
  albumDeletedItems = null;
  albumCountMax = 10;
  albumFileSizeMax = 5; // MB

  fuho_sentence_max_row = 5;
  fuho_sentence_max_row_len = 35;
  schedule_address_len = 50;
  schedule_name_len = 5;
  seko_style_name_len = 8;
  relation_max = 4;

  item_list = null;
  item_list_company = null;
  item_list_depart = null;
  host_url = window.location.origin;

  homei_data_all = null;
  homei_list = null;
  homei_filter = {
    kumotsu: true, chobun: true, msg: true, koden: true
  };
  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  hoyo_pagination = {
    pages: new Array(),
    current: 0
  };

  hoyo_edit: HoyoGetResponse = null;
  hoyo_sentence_max_row = 7;
  hoyo_sentence_max_row_len = 35;

  af_wish_edit: AfWishForGet = null;
  af_activity_edit: AfActivityForGet = null;

  showing_modal = false;
  errField = new Map();

  init_tab = '';
  lack_flg = false;
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private zipcodeSvc: ZipcodeService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }

  async ngOnInit() {

    this.staff_list = Utils.getStaffList(this.login_company.id);
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.seko_id = Number(params.get('id'));
    });
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.getParam(params);
    });
    if (this.seko_id) {
      await this.getSekoInfo();
    } else {
      this.initSekoInfo();
      this.initJSControl();
    }
    this.initControl();
    if (this.albumItems && this.albumItems.length) {
      this.initAlbumItemTable();
    } else {
      this.albumItems = new Array();
    }
    await this.getCompanyItemList();
    if (this.seko_info && this.seko_info.seko_department && this.seko_info.seko_department.id) {
      await this.getDeparttemList(this.seko_info.seko_department.id);
    }
    this.getFuhoSample();
    this.getHoyoSample();
    this.loaderSvc.dismiss();
  }

  getParam(params) {
    if (params.has('tab')) {
      this.init_tab = params.get('tab');
      if (this.init_tab === 'homei-list' && params.has('filter')) {
        const filter = params.get('filter');
        this.homei_filter.chobun = false;
        this.homei_filter.kumotsu = false;
        this.homei_filter.koden = false;
        this.homei_filter.msg = false;
        switch (filter) {
          case 'chobun':
            this.homei_filter.chobun = true;
            break;
          case 'kumotsu':
            this.homei_filter.kumotsu = true;
            break;
          case 'koden':
            this.homei_filter.koden = true;
            break;
          case 'msg':
            this.homei_filter.msg = true;
            break;
        }
      }
    }
  }

  initSekoInfo() {
    this.seko_info = new SekoGetResponse();
    this.seko_info.henreihin_rate = 1;
    this.seko_info.moshu_display_place = 0;
    this.seko_info.seireki_display_flg = false;
    this.seko_info.schedules = new Array();
    for (let i = 0; i < 4; i++) {
      const sekoSchedule = new SekoScheduleForGet();
      if (this.schedule_list && this.schedule_list[i]) {
        sekoSchedule.schedule = this.schedule_list[i];
      } else {
        sekoSchedule.schedule = new ScheduleResponse();
      }
      sekoSchedule.display_num = i + 1;
      sekoSchedule.hall = new SekoHall();
      sekoSchedule.schedule_name = sekoSchedule.schedule.name;
      this.seko_info.schedules.push(sekoSchedule);
    }
    this.seko_info.seko_company = new BaseGetResponse();
    this.seko_info.seko_department = new BaseGetResponse();
    this.seko_info.moshu = new MoshuForGet();
    this.seko_info.seko_contact = new SekoContactForGet();
    this.seko_info.kojin = new Array();
    const firstKojin = new KojinForGet();
    firstKojin.age_kbn = '満';
    this.seko_info.kojin.push(firstKojin);
    this.seko_info.videos = new Array();
    this.seko_info.albums = new Array();
    this.seko_info.order_staff = this.login_info.staff.id;
    this.seko_info.seko_staff = this.login_info.staff.id;
    this.seko_info.af_staff = this.login_info.staff.id;
    if (this.seko_style_list && this.seko_style_list.length) {
      this.seko_info.seko_style = this.seko_style_list[0];
      this.seko_info.seko_style_name = this.seko_style_list[0].name;
    }
    this.initHenreihinKakegami();

  }

  initSekoSchedules() {
    for (const sekoSchedule of this.seko_info.schedules) {
      if (!sekoSchedule.hall) {
        sekoSchedule.hall = new SekoHall();
      }
    }
    let display_num = this.seko_info.schedules.length;
    for (const schedule of this.schedule_list) {
      const tmp = this.seko_info.schedules.filter(v => (v.schedule && v.schedule.id === schedule.id));
      if (!tmp.length) {
        display_num++;
        const sekoSchedule = new SekoScheduleForGet();
        sekoSchedule.schedule = schedule;
        sekoSchedule.display_num = display_num;
        sekoSchedule.hall = new SekoHall();
        this.seko_info.schedules.push(sekoSchedule);
      }
    }
    for (let i = this.seko_info.schedules.length; i < 4; i++) {
      display_num++;
      const sekoSchedule = new SekoScheduleForGet();
      sekoSchedule.schedule = new ScheduleResponse();
      sekoSchedule.display_num = display_num;
      sekoSchedule.hall = new SekoHall();
      this.seko_info.schedules.push(sekoSchedule);
    }

  }

  initControl() {
    if (this.login_company) {
      this.allBaseCombo.values = Utils.getAllBaseList(this.login_company);
      this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
      this.hallCombo.values = Utils.GetAllHallList(this.login_company);
      this.selfHallCombo1.values = this.hallCombo.values.filter(v => v.data.base_type === this.Const.BASE_TYPE_PLACE_SELF);
      this.selfHallCombo2.values = this.hallCombo.values.filter(v => v.data.base_type === this.Const.BASE_TYPE_PLACE_SELF);
    }
    if (this.staff_list) {
      this.staffCombo.values = this.staff_list.map(value => ({ name: value.name, value: value.id }));
    }
    if (this.seko_style_list) {
      this.sekoStyleCombo.values = this.seko_style_list.map(value => ({ name: value.name, value: value.id }));
    }
  }

  initJSControl() {
    $(function () {
      $.fn.autoKana('#soke_name', '#soke_kana', { katakana: true });
      $.fn.autoKana('#kojin_name', '#kojin_kana', { katakana: true });
      $.fn.autoKana('#moshu_name', '#moshu_kana', { katakana: true });
      $.fn.autoKana('#add_kojin_name', '#add_kojin_kana', { katakana: true });
    });
    setTimeout(() => {
      $('.tabular.menu .item').tab();
      if (this.seko_id && this.init_tab) {
        $('.tabular.menu .item').tab('change tab', this.init_tab);
      }
      $('.ui.toggle.checkbox').checkbox();
      $('.ui.answered.checkbox').checkbox();
      $('.ui.radio.checkbox').checkbox();
    }, 100);
  }

  initHenreihinKakegami() {
    this.seko_info.henrei_kakegami = new HenreihinKakegamiForGet();
    if (this.henrei_param) {
      this.seko_info.henrei_kakegami.omotegaki = this.henrei_param.henreihin_omote_default;
      this.seko_info.henrei_kakegami.mizuhiki = this.henrei_param.henreihin_mizuhiki_default;
      this.seko_info.henrei_kakegami.package_type_name = this.henrei_param.henreihin_hoso_default;
    }
    if (this.seko_info.soke_name) {
      this.seko_info.henrei_kakegami.okurinushi_name = this.seko_info.soke_name + '家';
    }
  }

  async getSekoInfo() {
    if (!this.seko_id) {
      return;
    }
    await this.httpClientService.getSekoByid(this.seko_id).then(async (sekoResponse) => {
      Utils.log(sekoResponse);
      if (this.login_company.base_type !== this.Const.BASE_TYPE_ADMIN && sekoResponse.seko_company.id !== this.login_company.id) {
        this.loaderSvc.dismiss();
        this.router.navigate(['foc/seko/edit/']);
        setTimeout(() => {
          this.sessionSvc.save('message', { type: 'warning', title: '施行情報が見つかりませんでした。' });
        }, 500);
        return;
      }
      this.seko_info = sekoResponse;
      // this.downloadIeiImage();
      Utils.formatSekoDate(this.seko_info);
      this.seko_info.moshu.mail_not_flg = !this.seko_info.moshu.mail_flg;
      if (!this.seko_info.seko_contact || !this.seko_info.seko_contact.mail_address) {
        this.lack_flg = true;
      } else {
        this.lack_flg = false;
      }
      if (!this.seko_info.seko_department) {
        this.seko_info.seko_department = new BaseGetResponse();
      }
      if (!this.seko_info.seko_contact) {
        this.seko_info.seko_contact = new SekoContactForGet();
      }
        this.getServiceList(this.seko_info.seko_department.id);
      if (!this.seko_info.henrei_kakegami) {
        this.initHenreihinKakegami();
      }
      // if (this.seko_info.kojin && this.seko_info.kojin.length >= 2) {
      //   this.kojin_edit = JSON.parse(JSON.stringify(this.seko_info.kojin[1]));
      // }
      let i = 0;
      for (const schedule of this.seko_info.schedules) {
        i++;
        schedule.display_num = i;
      }
      this.albumItems = new Array();
      for (const album of this.seko_info.albums) {
        this.albumItems.push(album);
      }
      this.initJSControl();
      this.initSekoSchedules();
      this.getHomeichoList(this.seko_info.id);
      this.calcHoyoPagination();

    }).catch(error => {
      this.sessionSvc.save('message', { type: 'error', title: '施行情報の取得が失敗しました。' });
      this.loaderSvc.dismiss();
      this.seko_id = null;
      this.seko_info = null;
      return;
    });
  }

  async getHomeichoList(seko_id) {
    await this.httpClientService.getHomeicho(seko_id).then((response) => {
      Utils.log(response);
      this.homei_data_all = response;
      this.filterHomeiList();
      setTimeout(() => {
        $('ui.toggle.homei.checkbox').checkbox();
      }, 100);

    }).catch(error => {
    });
  }

  filterHomeiList() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.homei_list = new Array();
    if (!this.homei_data_all || !this.homei_data_all.length) {
      return;
    }
    for (const homei_data of this.homei_data_all) {
      for (const homei_detail of homei_data.details) {
        if ((!this.homei_filter.chobun && homei_detail.item.service.id === this.Const.SERVICE_ID_CHOBUN) ||
          (!this.homei_filter.kumotsu && homei_detail.item.service.id === this.Const.SERVICE_ID_KUMOTSU) ||
          (!this.homei_filter.koden && homei_detail.item.service.id === this.Const.SERVICE_ID_KODEN) ||
          (!this.homei_filter.msg && homei_detail.item.service.id === this.Const.SERVICE_ID_MESSAGE)) {
          continue;
        }
        let okurinushi_name = '';
        if (homei_detail.chobun) {
          okurinushi_name = homei_detail.chobun.okurinushi_name;
        } else if (homei_detail.kumotsu) {
          okurinushi_name = homei_detail.kumotsu.okurinushi_name;
        }
        this.homei_list.push({
          entry_id: homei_data.id,
          entry_name: homei_data.entry_name,
          entry_ts: homei_data.entry_ts,
          okurinushi_name: okurinushi_name,
          service_name: homei_detail.item.service.name,
          item_name: homei_detail.item_name,
        });

      }
    }
    this.calcPagination();
  }

  checkHomeiHaveData(service_id) {
    if (!this.homei_data_all || !this.homei_data_all.length) {
      return false;
    }
    for (const homei_data of this.homei_data_all) {
      const filter = homei_data.details.filter(v => v.item.service.id === service_id);
      if (filter && filter.length > 0) {
        return true;
      }
    }
    return false;
  }

  calcPagination() {
    if (!this.homei_list || !this.homei_list.length) {
      return;
    }
    const count = Math.ceil(this.homei_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  async downloadHomeiPdf(service_id) {
    this.loaderSvc.call();
    this.sessionSvc.clear('message');
    if (!this.checkHomeiHaveData(service_id)) {
      setTimeout(() => {
        this.sessionSvc.save('message', { type: 'warning', title: '出力対象データがございません。' });
        this.loaderSvc.dismiss();
      }, 300);
      return;
    }

    let func_name = null;
    let title_name = null;
    switch (service_id) {
      case this.Const.SERVICE_ID_KUMOTSU:
        func_name = 'downloadHomeiKumotsuPdf';
        title_name = '芳名帳(供花供物)';
        break;
      case this.Const.SERVICE_ID_CHOBUN:
        func_name = 'downloadHomeiChobunPdf';
        title_name = '芳名帳(弔文)';
        break;
      case this.Const.SERVICE_ID_MESSAGE:
        func_name = 'downloadHomeiMsgPdf';
        title_name = '芳名帳(追悼MSG)';
        break;
      case this.Const.SERVICE_ID_KODEN:
        func_name = 'downloadHomeiKodenPdf';
        title_name = '香典帳';
        break;
    }
    if (!func_name) {
      this.loaderSvc.dismiss();
      return;
    }
    this.httpClientService[func_name](this.seko_id)
      .then(async (response) => {
        this.homeichoStreamDownload(response, title_name);
        this.loaderSvc.dismiss();
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: title_name + 'PDFの出力が失敗しました。' });
        this.loaderSvc.dismiss();
      });
  }

  downloadMsgPdf() {
    this.loaderSvc.call();
    this.sessionSvc.clear('message');
    if (!this.checkHomeiHaveData(this.Const.SERVICE_ID_MESSAGE)) {
      setTimeout(() => {
        this.sessionSvc.save('message', { type: 'warning', title: '出力対象データがございません。' });
        this.loaderSvc.dismiss();
      }, 300);
      return;
    }
    this.httpClientService.downloadMsgPdf(this.seko_id)
      .then(async (response) => {
        this.homeichoStreamDownload(response, '追悼メッセージ');
        this.loaderSvc.dismiss();
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '追悼メッセージPDFの出力が失敗しました。' });
        this.loaderSvc.dismiss();
      });

  }
  homeichoStreamDownload(stream, title_name) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = title_name + '-' + this.seko_id + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }

  async downloadIeiImage() {
    if (this.seko_info.kojin.length) {
      for (const kojin of this.seko_info.kojin) {
        if (kojin.iei_file_name) {
          this.httpClientService.downloadImage(kojin.iei_file_name)
            .then((response) => {
              kojin.iei_file = response;
            }).catch(error => {
            });
        }
      }
    }
  }

  getServiceList(base_id) {
    if (!base_id) {
      return;
    }

    this.service_list = Utils.getServiceList(base_id);
    if (this.seko_info.services && this.service_list) {
      for (const service of this.service_list) {
        const seko_service = this.seko_info.services.find(v => v.hall_service.id === service.id);
        if (seko_service) {
          service.selected = true;
          service.henreihin_select_flg = !!seko_service.henreihin_select_flg;
        } else {
          service.selected = false;
          service.henreihin_select_flg = false;
        }
      }
    }
    this.getServiceReceptionTermList(base_id);
    setTimeout(() => {
      $('.ui.service.checkbox').checkbox();
    }, 100);
  }

  async getServiceReceptionTermList(base_id) {
    const request = new ServiceReceptionTermGetRequest();
    request.department_id = Utils.getDepartBaseId(base_id);
    await this.httpClientService.getServiceReceptionTermList(request).then((response) => {
      Utils.log(response);
      this.service_reception_term_list = response;

    }).catch(error => {
      this.sessionSvc.clear('message');
    });
  }

  haveService(service_id) {
    if (!this.service_list) {
      return false;
    }
    if (this.service_list.find(v => (v.id === service_id && v.selected))) {
      return true;
    }
    return false;
  }

  async getCompanyItemList() {
    const request = new ItemGetRequest();
    if (this.seko_id) {
      request.base_id = this.seko_info.seko_company.id;
    } else {
      request.base_id = this.login_company.id;
    }
    await this.httpClientService.getItemList(request).then((response) => {
      this.item_list_company = response;
      this.initItemList(this.item_list_company);
      Utils.log(this.item_list_company);

    }).catch(error => {
    });
  }

  async getDeparttemList(base_id) {
    const depart_id = Utils.getDepartBaseId(base_id);
    if (depart_id && depart_id !== base_id) {
      await this.getDeparttemList(depart_id);
    }
    const request = new ItemGetRequest();
    request.base_id = base_id;
    await this.httpClientService.getItemList(request).then(async (response) => {
      if (this.item_list_depart) {
        this.item_list_depart = this.item_list_depart.concat(response);
      } else {
        this.item_list_depart = response;
      }
      this.initItemList(this.item_list_depart);
      Utils.log(this.item_list_depart);
      if (this.item_list_company) {
        this.item_list = this.item_list_company.concat(this.item_list_depart);
      } else {
        this.item_list = this.item_list_depart;
      }

    }).catch(error => {
    });
  }

  async initItemList(item_list) {
    if (!item_list) {
      return;
    }
    let company_id = null;
    if (this.seko_id) {
      company_id = this.seko_info.seko_company.id;
    } else {
      company_id = this.login_company.id;
    }
    for (const item of item_list) {
      item.image_file = this.host_url + '/media/items/' + company_id + '/' + item.hinban + '.jpg';
      if (!Utils.isBetweenTerm(item.begin_date, item.end_date)) {
        item.disabled = true;
        continue;
      }
      if (!this.seko_id || this.lack_flg) {
        item.selected1 = true;
        item.selected2 = false;
      } else {
        if (this.seko_info.items.find(v => (v.item.id === item.id && v.seko_before_flg))) {
          item.selected1 = true;
        } else {
          item.selected1 = false;
        }
        if (this.seko_info.items.find(v => (v.item.id === item.id && v.seko_after_flg))) {
          item.selected2 = true;
        } else {
          item.selected2 = false;
        }
      }
    }
  }
  selectItems(service_id, select_flg) {
    if (!this.item_list) {
      return;
    }
    for (const item of this.item_list) {
      if (item.service === service_id) {
        item.selected1 = select_flg;
      }
    }
  }
  imageLoadError(item) {
    item.image_file = null;
  }

  getFuhoSample() {
    const request = new CompanyFuhoSampleGetRequest();
    request.company_id = this.login_company.id;
    this.httpClientService.getCompanyFuhoSampleList(request).then((response) => {
      Utils.log(response);
      this.fuho_sample_list = response;

    }).catch(error => {
    });
  }

  getHoyoSample() {
    const request = new HoyoSampleGetRequest();
    request.company = this.login_company.id;
    this.httpClientService.getHoyoSampleList(request).then((response) => {
      Utils.log(response);
      this.hoyo_sample_list = response;

    }).catch(error => {
    });
  }

  sokeNamechange() {
    if (!this.seko_info.henrei_kakegami.okurinushi_name) {
      if (this.seko_info.soke_name) {
        this.seko_info.henrei_kakegami.okurinushi_name = this.seko_info.soke_name + '家';
      }
    }
  }

  moshuZipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.seko_info.moshu.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.seko_info.moshu.prefecture = address.prefecture;
        this.seko_info.moshu.address_1 = address.address_1;
        this.seko_info.moshu.address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  hoyoZipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.hoyo_edit.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.hoyo_edit.prefecture = address.prefecture;
        this.hoyo_edit.address_1 = address.address_1;
        this.hoyo_edit.address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  sekoContactZipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.seko_info.seko_contact.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.seko_info.seko_contact.prefecture = address.prefecture;
        this.seko_info.seko_contact.address_1 = address.address_1;
        this.seko_info.seko_contact.address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  scheduleZipcodeChange(sekoSchedule: SekoScheduleForGet) {
    const request = new AddressGetRequest();
    request.zip_code = sekoSchedule.hall_zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        sekoSchedule.hall_address = address.prefecture + address.address_1 + address.address_2;
        if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_TSUYA) {
          for (const schedule of this.seko_info.schedules) {
            if (schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU) {
              if (!schedule.hall_zip_code) {
                schedule.hall_zip_code = sekoSchedule.hall_zip_code;
              }
              if (!schedule.hall_address) {
                schedule.hall_address = sekoSchedule.hall_address;
              }
            }
          }
        }
      }
    }).catch(error => { });

  }

  hallChange(event, sekoSchedule: SekoScheduleForGet) {
    if (event) {
      if (event.data) {
        sekoSchedule.hall_tel = event.data.tel;
        sekoSchedule.hall_zip_code = event.data.zip_code;
        sekoSchedule.hall_address = event.data.prefecture + event.data.address_1 + event.data.address_2;
        sekoSchedule.hall_address += event.data.address_3 ? event.data.address_3 : '';
      }
      if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_TSUYA) {
        for (const schedule of this.seko_info.schedules) {
          if (schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU) {
            if (!schedule.hall_name) {
              schedule.hall_name = event.name;
              this.hallComboEm2.setName(event.name);
            }

            if (event.data) {
              if (!schedule.hall.id) {
                schedule.hall.id = event.data.id;
                this.hallComboEm2.setValue(event.data.id);
                if (!schedule.hall_tel) {
                  schedule.hall_tel = event.data.tel;
                }
                if (!schedule.hall_zip_code) {
                  schedule.hall_zip_code = event.data.zip_code;
                }
                if (!schedule.hall_address) {
                  schedule.hall_address = event.data.prefecture + event.data.address_1 + event.data.address_2;
                  schedule.hall_address += event.data.address_3 ? event.data.address_3 : '';
                }
                if (event.data.base_type === CommonConstants.BASE_TYPE_PLACE_SELF) {
                  if (!this.seko_info.seko_department.id) {
                    this.seko_info.seko_department = event.data;
                    this.getServiceList(event.data.id);
                    this.sekoBaseComboEm.setValue(event.data.id);
                    this.item_list_depart = null;
                    this.getDeparttemList(event.data.id);
                  }
                  if (!this.seko_info.fuho_contact_name) {
                    this.seko_info.fuho_contact_name = event.data.name;
                    this.contactComboEm.setValue(event.data.id);
                  }
                  if (!this.seko_info.fuho_contact_tel) {
                    this.seko_info.fuho_contact_tel = event.data.tel;
                  }

                }
              }
            }
          }
        }
      } else if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU
        && event.data && event.data.base_type === CommonConstants.BASE_TYPE_PLACE_SELF) {
        if (!this.seko_info.seko_department.id) {
          this.seko_info.seko_department = event.data;
          this.getServiceList(event.data.id);
          this.sekoBaseComboEm.setValue(event.data.id);
          this.item_list_depart = null;
          this.getDeparttemList(event.data.id);
        }
        if (!this.seko_info.fuho_contact_name) {
          this.seko_info.fuho_contact_name = event.data.name;
          this.contactComboEm.setValue(event.data.id);
        }
        if (!this.seko_info.fuho_contact_tel) {
          this.seko_info.fuho_contact_tel = event.data.tel;
        }
      }
    }
  }

  scheduleItemChange(sekoSchedule: SekoScheduleForGet, key: string) {
    if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_TSUYA) {
      for (const schedule of this.seko_info.schedules) {
        if (schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU && !schedule[key]) {
          schedule[key] = sekoSchedule[key];
        }
      }
    }
  }

  scheduleDateChenge(event, sekoSchedule: SekoScheduleForGet) {
    if (event) {
      if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_TSUYA) {
        for (const schedule of this.seko_info.schedules) {
          if (schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU) {
            if (!schedule.schedule_date) {
              schedule.schedule_date = new Date(sekoSchedule.schedule_date.getTime() + 1000 * 60 * 60 * 24);
              this.scheduleDateEm.setDate(schedule.schedule_date);
            }

          }
        }
        if (!this.seko_info.fuho_site_end_date) {
          this.seko_info.fuho_site_end_date = new Date(sekoSchedule.schedule_date.getTime() + 1000 * 60 * 60 * 24 * 8);
          this.siteEndDateEm.setDate(this.seko_info.fuho_site_end_date);

        }
      } else if (sekoSchedule.schedule && sekoSchedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU
        && !this.seko_info.fuho_site_end_date) {
        this.seko_info.fuho_site_end_date = new Date(sekoSchedule.schedule_date.getTime() + 1000 * 60 * 60 * 24 * 7);
        // this.seko_info.fuho_site_end_date.setDate(this.seko_info.fuho_site_end_date.getDate() + 7);
        this.siteEndDateEm.setDate(this.seko_info.fuho_site_end_date);
      }
    }

  }

  sekoDepartChange(event) {
    if (event.data) {
      this.getServiceList(event.data.id);
      this.item_list_depart = null;
      this.getDeparttemList(event.data.id);
    }

  }

  contactChange(event) {
    this.seko_info.fuho_contact_tel = null;
    if (event.data && !this.seko_info.fuho_contact_tel) {
      this.seko_info.fuho_contact_tel = event.data.tel;
    }
  }

  sekoStyleChange(event) {
    if (event.name) {
      this.seko_info.seko_style_name = event.name;
    }
  }

  calcAge(type) {
    if (type === 1) {
      if (this.seko_info.kojin[0].birth_date && this.seko_info.kojin[0].death_date) {
        this.seko_info.kojin[0].age = Utils.getAge(this.seko_info.kojin[0].birth_date, this.seko_info.kojin[0].death_date);
      }
    }
    if (type === 2) {
      if (this.showing_modal) {
        return;
      }
      if (this.kojin_edit.birth_date && this.kojin_edit.death_date) {
        this.kojin_edit.age = Utils.getAge(this.kojin_edit.birth_date, this.kojin_edit.death_date);
      }
    }

  }

  onDragOverPortrait(event) {
    event.preventDefault();
  }

  onDropPortrait(event, file_type_id = 1) {
    event.preventDefault();
    if (!event.dataTransfer || !event.dataTransfer.files) {
      return;
    }
    this.addPortrait(event.dataTransfer.files, file_type_id);
  }

  addPortrait(files, file_type_id = 1) {
    // var files = event['target']['files']; // FileList object
    if (!files || !files.length) {
      return;
    }
    this.sessionSvc.clear('message');
    this.message = null;
    if (files.length !== 1) {
      const message = {
        type: 'warning',
        title: '一つのファイルを指定してください。'
      };
      if (file_type_id === 1) {
        this.sessionSvc.save('message', message);
      } else {
        this.message = message;
      }
      return;

    }

    if (!files[0].type.includes('image')) {
      const message = {
        type: 'warning',
        title: '画像ファイルを指定してください。'
      };
      if (file_type_id === 1) {
        this.sessionSvc.save('message', message);
      } else {
        this.message = message;
      }
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      if (file_type_id === 1) {
        this.seko_info.kojin[0].iei_file = e['target']['result'];
      } else {
        this.kojin_edit.iei_file = e['target']['result'];
      }
    };

    reader.readAsDataURL(files[0]);

  }

  selectFile(file_type_id: number) {
    const fileUpload = this.imageFileUpload.nativeElement;
    fileUpload.accept = 'image/*';
    if (file_type_id === 2) {
      fileUpload.multiple = true;
    } else {
      fileUpload.multiple = false;
    }

    fileUpload.onchange = () => {
      if (file_type_id === 2) {
        this.addImageToAlbum(fileUpload.files);
      } else {
        this.addPortrait(fileUpload.files, file_type_id);
      }
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  clearPortrait(file_type_id = 1) {
    if (file_type_id === 1) {
      this.seko_info.kojin[0].iei_file = null;
      this.seko_info.kojin[0].iei_file_name = null;
    } else {
      this.kojin_edit.iei_file = null;
      this.kojin_edit.iei_file_name = null;
    }
  }

  showKojin2(kojin2BirthDateEm, kojin2DeathDateEm) {
    this.showing_modal = true;
    this.message = null;
    if (!this.seko_info.kojin || this.seko_info.kojin.length < 2) {
      this.kojin_edit = new KojinForGet();
      this.kojin_edit.age_kbn = '満';
      if (this.seko_info.kojin[0].death_date) {
        kojin2DeathDateEm.setDate(this.seko_info.kojin[0].death_date);
      }
    } else {
      this.kojin_edit = JSON.parse(JSON.stringify(this.seko_info.kojin[1]));
      if (this.kojin_edit.birth_date) {
        kojin2BirthDateEm.setDate(this.kojin_edit.birth_date);
      }
      if (this.kojin_edit.death_date) {
        kojin2DeathDateEm.setDate(this.kojin_edit.death_date);
      }
    }
    $('#additional-kojin').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    this.showing_modal = false;
  }

  saveKojin2(kojin2BirthDateEm, kojin2DeathDateEm) {
    if (!this.kojin2Validate()) {
      return;
    }
    this.showing_modal = true;
    if (this.seko_info.kojin.length < 2) {
      this.seko_info.kojin.push(JSON.parse(JSON.stringify(this.kojin_edit)));
    } else {
      this.seko_info.kojin[1] = JSON.parse(JSON.stringify(this.kojin_edit));
    }
    $('#additional-kojin').modal('hide');
    kojin2BirthDateEm.clear();
    kojin2DeathDateEm.clear();
    this.showing_modal = false;
  }

  closeKojin2(kojin2BirthDateEm, kojin2DeathDateEm) {
    // this.kojin_edit = null;
    this.showing_modal = true;
    kojin2BirthDateEm.clear();
    kojin2DeathDateEm.clear();
    this.showing_modal = false;
  }

  kojin2Validate() {
    const msgs = new Array();
    this.message = null;
    if (!this.kojin_edit.name) {
      msgs.push('故人名');
    }
    if (!this.kojin_edit.kana) {
      msgs.push('故人名カナ');
    }
    if (!this.kojin_edit.moshu_kojin_relationship) {
      msgs.push('喪主からみた続柄');
    }
    if (!this.kojin_edit.birth_date) {
      msgs.push('生年月日');
    }
    if (!this.kojin_edit.death_date) {
      msgs.push('逝去日');
    }
    if (!this.kojin_edit.age_kbn) {
      msgs.push('年齢区分');
    }
    if (this.kojin_edit.age === undefined || this.kojin_edit.age === null) {
      msgs.push('年齢');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (this.kojin_edit.birth_date &&
      this.kojin_edit.death_date &&
      this.kojin_edit.birth_date > this.kojin_edit.death_date) {
      this.message = {
        type: 'warning',
        title: '逝去日は生年月日より後の日付を入力してください。',
      };
      return false;
    }
    if (this.kojin_edit.moshu_kojin_relationship.length > this.relation_max) {
      this.message = { type: 'warning', title: '喪主からみた続柄を' + this.relation_max + '文字以内で入力してください。' };
      return false;
    }

    return true;
  }

  deleteKojin2(kojin2BirthDateEm, kojin2DeathDateEm): void {
    this.seko_info.kojin.splice(1);
    // this.sessionSvc.save('message', { type: 'info', title: '追加故人を削除しました。' });
    kojin2BirthDateEm.clear();
    kojin2DeathDateEm.clear();
    /*
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            msg1: '追加故人情報を削除します、よろしいですか？',
          },
          width: '100%',
          maxWidth: '450px',
          maxHeight: '90%'
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.seko_info.kojin.splice(1);
          } else {
            this.showKojin();
          }
        });
    */
  }

  showFuhoSample() {
    $('#fuho-sample').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  select_fuho_sample(text) {
    this.seko_info.fuho_sentence = text;
    $('#fuho-sample').modal('hide');
  }

  showHoyoSample() {
    $('#hoyo-sample').modal({
      allowMultiple: true,
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  select_hoyo_sample(text) {
    this.hoyo_edit.hoyo_sentence = text;
    $('#hoyo-sample').modal('hide');
  }

  selectInvoiceFile(type = 0) {
    const fileUpload = this.invoiceFileUpload.nativeElement;
    fileUpload.accept = '.pdf';

    fileUpload.onchange = () => {
      this.addInvoice(fileUpload.files, type);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  addInvoice(files, type = 0) {
    // var files = event['target']['files']; // FileList object
    if (!files || !files.length) {
      return;
    }
    this.sessionSvc.clear('message');
    let upload_file = null;
    if (!files[0].name.includes('.pdf')) {
      this.sessionSvc.save('message', { type: 'warning', title: 'PDFファイルを指定してください。' });
      return;
    }
    upload_file = { name: files[0].name, file: null };
    switch (type) {
      case 0:
         this.seko_info.invoice_file = upload_file;
        break;
      case 1:
        this.seko_info.attached_file1_buf = upload_file;
        break;
      case 2:
        this.seko_info.attached_file2_buf = upload_file;
        break;
      case 3:
        this.seko_info.attached_file3_buf = upload_file;
        break;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      upload_file.file = e['target']['result'];
    };

    reader.readAsDataURL(files[0]);

  }

  clearInvoice(type = 0) {
    switch (type) {
      case 0:
        this.seko_info.invoice_file = null;
        this.seko_info.invoice_file_name = null;
        break;
      case 1:
        this.seko_info.attached_file1 = null;
        this.seko_info.attached_file1_buf = null;
        break;
      case 2:
        this.seko_info.attached_file2 = null;
        this.seko_info.attached_file2_buf = null;
        break;
      case 3:
        this.seko_info.attached_file3 = null;
        this.seko_info.attached_file3_buf = null;
        break;
    }
  }

  selectItem(item) {
    item.selected1 = !item.selected1;
  }
  selectItem1(item) {
  }

  showVideo(event, index: number, liveBeginTsEm = null, liveEndTsEm = null, DeliveryEndTsEm = null) {
    this.video_edit_index = index;
    this.message = null;
    if (index < 0) {
      this.video_edit = new VideoForGet();
      this.video_edit.type = 1;
      liveBeginTsEm.enabled();
      liveEndTsEm.enabled();
    } else {
      if (event.target.classList.contains('icon')) {
        return;
      }
      this.video_edit = JSON.parse(JSON.stringify(this.seko_info.videos[index]));
      if (this.video_edit.live_begin_ts) {
        liveBeginTsEm.setDate(this.video_edit.live_begin_ts);
        this.video_edit.type = 1;
        liveBeginTsEm.enabled();
        liveEndTsEm.enabled();
      } else {
        this.video_edit.type = 2;
        liveBeginTsEm.clear();
        liveEndTsEm.clear();
        liveBeginTsEm.disabled();
        liveEndTsEm.disabled();
      }
      if (this.video_edit.live_end_ts) {
        liveEndTsEm.setDate(this.video_edit.live_end_ts);
      }
      if (this.video_edit.delivery_end_ts) {
        DeliveryEndTsEm.setDate(this.video_edit.delivery_end_ts);
      }
    }
    $('#video-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  saveVideo(liveBeginTsEm, liveEndTsEm, DeliveryEndTsEm) {
    if (!this.videoValidate()) {
      return;
    }
    if (this.video_edit_index < 0) {
      this.seko_info.videos.push(JSON.parse(JSON.stringify(this.video_edit)));
    } else {
      this.seko_info.videos[this.video_edit_index] = JSON.parse(JSON.stringify(this.video_edit));
    }

    $('#video-edit').modal('hide');
    liveBeginTsEm.clear();
    liveEndTsEm.clear();
    DeliveryEndTsEm.clear();
  }

  closeVideo(liveBeginTsEm, liveEndTsEm, DeliveryEndTsEm) {
    liveBeginTsEm.clear();
    liveEndTsEm.clear();
    DeliveryEndTsEm.clear();
  }

  videoTypeChange(type, liveBeginTsEm, liveEndTsEm) {
    if (type === 1) {
      liveBeginTsEm.enabled();
      liveEndTsEm.enabled();
    } else {
      liveBeginTsEm.clear();
      liveEndTsEm.clear();
      liveBeginTsEm.disabled();
      liveEndTsEm.disabled();
    }
  }
  videoValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.video_edit.title) {
      msgs.push('タイトル');
    }
    if (!this.video_edit.youtube_code) {
      msgs.push('Youtubeコード');
    }
    if (this.video_edit.type === 1 && !this.video_edit.live_begin_ts) {
      msgs.push('ライブ開始日時');
    }
    if (this.video_edit.type === 1 && !this.video_edit.live_end_ts) {
      msgs.push('ライブ終了日時');
    }
    if (!this.video_edit.delivery_end_ts) {
      msgs.push('配信終了日時');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (this.video_edit.type === 1) {
      if (this.video_edit.live_begin_ts > this.video_edit.live_end_ts) {
        this.message = {
          type: 'warning',
          title: 'ライブ終了日時はライブ開始日時より後の日時を入力してください。',
        };
        return false;
      }
      if (this.video_edit.live_begin_ts > this.video_edit.delivery_end_ts) {
        this.message = {
          type: 'warning',
          title: '配信終了日時はライブ開始日時より後の日時を入力してください。',
        };
        return false;
      }
      if (this.video_edit.live_end_ts > this.video_edit.delivery_end_ts) {
        this.message = {
          type: 'warning',
          title: '配信終了日時はライブ終了日時より後の日時を入力してください。',
        };
        return false;
      }
    }

    return true;
  }

  deleteVideo(index) {
    this.seko_info.videos.splice(index);
  }

  onDragOverAlbum(event) {
    event.preventDefault();
  }

  onDropAlbum(event) {
    event.preventDefault();
    if (this.login_company.base_type === this.Const.BASE_TYPE_ADMIN) {
      return;
    }
    if (!event.dataTransfer || !event.dataTransfer.files) {
      return;
    }
    this.addImageToAlbum(event.dataTransfer.files);
  }

  addImageToAlbum(files) {
    this.sessionSvc.clear('message');
    if (!files || !files.length) {
      return;
    }
    if (files.length + this.albumItems.length > this.albumCountMax) {
      this.sessionSvc.save('message', { type: 'warning', title: '登録可能ファイル数は' + this.albumCountMax + 'ファイルまでです。' });
      return;
    }

    for (const file of files) {
      if (!file.type.includes('image')) {
        this.sessionSvc.save('message', { type: 'warning', title: '画像ファイル以外は登録できません。' });
        return;
      }
      if (file.size > this.albumFileSizeMax * 1024 * 1024) {
        this.sessionSvc.save('message', { type: 'warning', title: '一つの画像ファイルサイズは' + this.albumFileSizeMax + 'MBまで登録可能です。' });
        return;
      }
    }
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.albumItems.push(
          {
            id: null,
            image_file: e['target']['result']
          });
        this.initAlbumItemTable();
      };

      reader.readAsDataURL(file);
    }
  }

  getAlbumItemsTable(campusEm) {
    if (!this.albumItems.length) {
      return;
    }
    const { width } = campusEm.getBoundingClientRect();
    const columnSize = Math.floor(width / this.albumImgWidth);
    if (columnSize !== this.albumColumnSize) {
      this.albumColumnSize = columnSize;
      this.initAlbumItemTable();
    }
    return this.albumItemsTable;
  }

  initAlbumItemTable() {
    if (!this.albumItems.length) {
      return;
    }
    // [1,2,3,4,5,6] => [ [1,2,3], [4,5,6] ]
    this.albumItemsTable = this.albumItems
      .filter((_, index) => index % this.albumColumnSize === 0)
      .map((_, rowIndex) =>
        this.albumItems.slice(
          rowIndex * this.albumColumnSize,
          rowIndex * this.albumColumnSize + this.albumColumnSize
        )
      );
  }

  deleteAlbumItem(row, col) {
    if (!this.albumItems.length) {
      return;
    }
    const index = row * this.albumColumnSize + col;
    const delItems = this.albumItems.splice(index, 1);
    if (delItems.length) {
      if (delItems[0].id) {
        if (!this.albumDeletedItems) {
          this.albumDeletedItems = new Array();
        }
        this.albumDeletedItems.push(delItems[0].id);
      }
    }
    this.initAlbumItemTable();
  }

  showAlbumItem(selected_item) {
    if (!selected_item) {
      return;
    }
    this.albumSeletedItem = selected_item;
    $('#album-item').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  reorderDroppedItem(event: CdkDragDrop<number[]>) {
    if (!this.albumItems.length) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.albumItems = this.albumItemsTable.reduce(
      (previous, current) => previous.concat(current),
      []
    );

    this.initAlbumItemTable();
  }

  reorderEnteredItem(event: CdkDragEnter<number[]>) {

  }

  schedulePositionChange(display_num, is_down = true) {
    const move_to = is_down ? display_num + 1 : display_num - 1;
    if (move_to < 1 || move_to > this.seko_info.schedules.length) {
      return;
    }
    const fist_anime = is_down ? 'slide up' : 'slide down';
    const last_anime = is_down ? 'slide down' : 'slide up';
    $('.transition-schedule' + display_num).transition({
      animation: fist_anime,
      duration: 50
    }).transition({
      animation: last_anime,
      duration: 50
    });
    setTimeout(() => {
      for (const sekoSchedule of this.seko_info.schedules) {
        if (sekoSchedule.display_num === display_num) {
          sekoSchedule.display_num = move_to;
          continue;
        }
        if (sekoSchedule.display_num === move_to) {
          sekoSchedule.display_num = display_num;
        }
      }
    }, 50);
    // this.sekoSchedules.splice(index_to, 0, this.sekoSchedules.splice(index, 1)[0]);
    setTimeout(() => {
      $('.transition-schedule' + move_to).transition({
        animation: fist_anime,
        duration: 50
      }).transition({
        animation: last_anime,
        duration: 50
      });
    }, 100);

  }

  closeMessage() {
    this.message = null;
  }
  closeMessageSub() {
    this.message_sub = null;
  }

  deleteData() {
    if (!this.seko_id) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '施行情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteSeko(this.seko_id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '施行情報を削除しました。' });
            this.loaderSvc.dismiss();
            this.router.navigate(['foc/seko/']);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '施行情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  closeWindow() {
    if (this.seko_id) {
      if (this.init_tab === 'af-list') {
        this.router.navigate(['foc/seko/af']);
      } else if (this.init_tab === 'homei-list') {
        this.router.navigate(['foc/koden/summary']);
      } else {
        this.router.navigate(['foc/seko/']);
      }
    } else {
      this.router.navigate(['foc/top/']);

    }
  }

  sendFuhoURL() {
    if (!this.seko_id) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '葬家専用ページURLを登録メールアドレス宛に送信します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        const req = new FuhoMailPostRequest();
        this.httpClientService.sendFuhoUrl(this.seko_id, req)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '葬家専用ページURLを登録メールアドレス宛に送信しました。' });
            this.loaderSvc.dismiss();
          }).catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '葬家専用ページURLの送信が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });

  }

  sendNoticeMail(file_title) {
    if (!this.seko_id) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '通知メールを送信します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        const req = new NoticeAttachedFilePostRequest();
        req.file_title = file_title ? file_title : 'その他添付ファイル';
        this.httpClientService.noticeAttachedFile(this.seko_id, req)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '通知メールを送信しました。' });
            this.loaderSvc.dismiss();
          }).catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '通知メールの送信が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });

  }

  downloadFuhoPDF() {
    if (this.seko_id) {
      this.loaderSvc.call();
      this.httpClientService.downloadFuhoPdf(this.seko_id)
        .then((response) => {
          const file_name = '訃報-' + this.seko_id + '.pdf';
          this.localStreamDownload(response, file_name);
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '訃報紙PDFの出力が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }

  downloadQRCode() {
    if (this.seko_id) {
      this.loaderSvc.call();
      this.httpClientService.downloadRQCode(this.seko_id)
        .then((response) => {
          const file_name = 'QRコード-' + this.seko_id + '.pdf';
          this.localStreamDownload(response, file_name);
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'QRコードの出力が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }


  localStreamDownload(stream, file_name) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }

  saveData() {
    this.errField.clear();
    if (!this.validate()) {
      return;
    }
    this.loaderSvc.call();
    if (this.seko_id) {
      const sekoPutRequestData = this.getSekoPostData();
      this.httpClientService.updateSeko(this.seko_id, sekoPutRequestData)
        .then(async (response) => {
          Utils.log(response);
          this.seko_id = response.id;
          await this.saveAlbum();
          await this.getSekoInfo();
          this.loaderSvc.dismiss();
          if (!this.albumApiError) {
            this.sessionSvc.save('message', { type: 'info', title: '施行情報を保存しました。' });
          }
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '施行情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    } else {
      const sekoPostRequestData = this.getSekoPostData();
      this.httpClientService.addSeko(sekoPostRequestData)
        .then(async (response) => {
          Utils.log(response);
          this.seko_id = response.id;
          this.location.go('foc/seko/edit/' + this.seko_id);
          await this.saveAlbum();
          await this.getSekoInfo();
          this.loaderSvc.dismiss();
          if (!this.albumApiError) {
            this.sessionSvc.save('message', { type: 'info', title: '施行情報を保存しました。' });
          }
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '施行情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }

  validate() {
    this.err_tab = null;
    if (!this.checkRequired()) {
      $('.tabular.menu .item').tab('change tab', this.err_tab);
      return false;
    }
    if (this.seko_info.kojin[0].birth_date > this.seko_info.kojin[0].death_date) {
      this.err_tab = 'base-info';
      this.sessionSvc.save('message', { type: 'warning', title: '逝去日は生年月日より後の日付を入力してください。' });
      this.errField.set('kojin_birth_date', true);
      this.errField.set('kojin_death_date', true);
      return false;
    }
    if (this.seko_info.kojin[0].moshu_kojin_relationship.length > this.relation_max) {
      this.err_tab = 'accessory-info';
      this.sessionSvc.save('message', { type: 'warning', title: '喪主からみた続柄を' + this.relation_max + '文字以内で入力してください。' });
      this.errField.set('kojin_moshu_kojin_relationship', true);
      return false;
    }
    if (!this.seko_info.seko_contact.tel && !this.seko_info.seko_contact.mobile_num) {
      this.err_tab = 'base-info';
      this.sessionSvc.save('message', { type: 'warning', title: '連絡先の電話番号と携帯番号のどちらかを入力してください。' });
      this.errField.set('seko_contact_tel', true);
      this.errField.set('seko_contact_mobile_num', true);
      return false;
    }
    const reg = new RegExp(CommonConstants.MAIL_PATTERN);
    if (this.seko_info.moshu.mail_address && !this.seko_info.moshu.mail_address.match(reg)) {
      this.err_tab = 'base-info';
      this.sessionSvc.save('message', { type: 'warning', title: '喪主のメール形式が正しくありません。' });
      this.errField.set('moshu_mail_address', true);
      return false;
    }
    if (!this.seko_info.seko_contact.mail_address.match(reg)) {
      this.err_tab = 'base-info';
      this.sessionSvc.save('message', { type: 'warning', title: '連絡先のメール形式が正しくありません。' });
      this.errField.set('seko_contact_mail_address', true);
      return false;
    }
    let index = 0;
    for (const schedule of this.seko_info.schedules) {
      if (schedule.begin_time && schedule.end_time && schedule.begin_time > schedule.end_time) {
        this.err_tab = 'accessory-info';
        this.sessionSvc.save('message', { type: 'warning', title: '日程(' + schedule.schedule_name + ')の終了時刻は開始時刻より後の時刻を入力してください。' });
        this.errField.set('schedule' + index + 'begin_time', true);
        this.errField.set('schedule' + index + 'end_time', true);
        return false;
      }
      if (schedule.schedule_name && schedule.hall_address && schedule.hall_address.length > this.schedule_address_len) {
        this.err_tab = 'accessory-info';
        this.sessionSvc.save('message', {
          type: 'warning', title: '日程(' + schedule.schedule_name + ')の住所を' + this.schedule_address_len + '文字以内で入力してください。'
        });
        this.errField.set('schedule' + index + 'hall_address', true);
        return false;
      }
      if (schedule.schedule_name && schedule.schedule_name.length > this.schedule_name_len) {
        this.err_tab = 'accessory-info';
        this.sessionSvc.save('message', {
          type: 'warning', title: '日程(' + schedule.schedule_name + ')の名称を' + this.schedule_name_len + '文字以内で入力してください。'
        });
        this.errField.set('schedule' + index + 'schedule_name', true);
        return false;
      }
      index++;
    }
    if (this.seko_info.seko_style_name && this.seko_info.seko_style_name.length > this.seko_style_name_len) {
      this.err_tab = 'accessory-info';
      this.sessionSvc.save('message', { type: 'warning', title: '施行形式表示名を' + this.seko_style_name_len + '文字以内で入力してください。' });
      this.errField.set('seko_style_name', true);
      return false;
    }
    const sentence_rows = this.seko_info.fuho_sentence.split('\n');
    if (sentence_rows.length > this.fuho_sentence_max_row) {
      this.err_tab = 'accessory-info';
      this.sessionSvc.save('message', { type: 'warning', title: '訃報文は' + this.fuho_sentence_max_row + '行以内で入力してください。' });
      this.errField.set('fuho_sentence', true);
      return false;
    }
    for (const sentence_row of sentence_rows) {
      if (sentence_row.length > this.fuho_sentence_max_row_len) {
        this.err_tab = 'accessory-info';
        this.sessionSvc.save('message', { type: 'warning', title: '訃報文は1行に' + this.fuho_sentence_max_row_len + '文字以内で入力してください。' });
        this.errField.set('fuho_sentence', true);
        return false;
      }
    }
    return true;

  }

  checkRequired() {
    const msgs = new Array();
    this.sessionSvc.clear('message');
    // 基本情報
    if (!this.seko_info.soke_name) {
      msgs.push('葬家名');
      this.errField.set('soke_name', true);
    }
    if (!this.seko_info.soke_kana) {
      msgs.push('葬家名カナ');
      this.errField.set('soke_kana', true);
    }
    if (!this.seko_info.kojin[0].name) {
      msgs.push('故人名');
      this.errField.set('kojin_name', true);
    }
    if (!this.seko_info.kojin[0].kana) {
      msgs.push('故人名カナ');
      this.errField.set('kojin_kana', true);
    }
    if (!this.seko_info.kojin[0].moshu_kojin_relationship) {
      msgs.push('喪主からみた続柄');
      this.errField.set('kojin_moshu_kojin_relationship', true);
    }
    if (!this.seko_info.kojin[0].birth_date) {
      msgs.push('生年月日');
      this.errField.set('kojin_birth_date', true);
    }
    if (!this.seko_info.kojin[0].death_date) {
      msgs.push('逝去日');
      this.errField.set('kojin_death_date', true);
    }
    if (!this.seko_info.kojin[0].age_kbn) {
      msgs.push('年齢区分');
      this.errField.set('kojin_age_kbn', true);
    }
    if (this.seko_info.kojin[0].age === undefined || this.seko_info.kojin[0].age === null) {
      msgs.push('年齢');
      this.errField.set('kojin_age', true);
    }
    if (!this.seko_info.order_staff) {
      msgs.push('受注担当者');
      this.errField.set('order_staff', true);
    }
    if (!this.seko_info.seko_staff) {
      msgs.push('施行担当者');
      this.errField.set('seko_staff', true);
    }
    if (!this.seko_info.af_staff) {
      msgs.push('AF担当者');
      this.errField.set('af_staff', true);
    }
    if (!this.seko_info.moshu.name) {
      msgs.push('喪主名');
      this.errField.set('moshu_name', true);
    }
    if (!this.seko_info.moshu.kana) {
      msgs.push('喪主名カナ');
      this.errField.set('moshu_kana', true);
    }
    if (!this.seko_info.moshu.kojin_moshu_relationship) {
      msgs.push('故人からみた続柄');
      this.errField.set('kojin_moshu_relationship', true);
    }
    if (!this.seko_info.moshu.zip_code) {
      msgs.push('郵便番号');
      this.errField.set('moshu_zip_code', true);
    }
    if (!this.seko_info.moshu.prefecture || !this.seko_info.moshu.address_1) {
      msgs.push('住所');
      if (!this.seko_info.moshu.prefecture) {
        this.errField.set('moshu_prefecture', true);
      }
      if (!this.seko_info.moshu.address_1) {
        this.errField.set('moshu_address_1', true);
      }
    }
    if (!this.seko_info.seko_contact.name) {
      msgs.push('連絡先氏名');
      this.errField.set('seko_contact_name', true);
    }
    if (!this.seko_info.seko_contact.kojin_relationship) {
      msgs.push('連絡先故人からみた続柄');
      this.errField.set('seko_contact_kojin_relationship', true);
    }
    if (!this.seko_info.seko_contact.zip_code) {
      msgs.push('連絡先郵便番号');
      this.errField.set('seko_contact_zip_code', true);
    }
    if (!this.seko_info.seko_contact.prefecture || !this.seko_info.seko_contact.address_1) {
      msgs.push('連絡先住所');
      if (!this.seko_info.seko_contact.prefecture) {
        this.errField.set('seko_contact_prefecture', true);
      }
      if (!this.seko_info.seko_contact.address_1) {
        this.errField.set('seko_contact_address_1', true);
      }
    }
    if (!this.seko_info.seko_contact.mail_address) {
      msgs.push('連絡先メールアドレス');
      this.errField.set('seko_contact_mail_address', true);
    }

    if (msgs.length > 0) {
      this.err_tab = 'base-info';
    }
    // 付加情報
    let index = 0;
    for (const schedule of this.seko_info.schedules) {
      if (schedule.schedule_name) {
        schedule.schedule_name = schedule.schedule_name.trim();
      }
      if (schedule.schedule && schedule.schedule.required_flg) {
        if (!schedule.schedule_name || !schedule.hall_name) {
          msgs.push('日程(' + schedule.schedule.name + ')');
          if (!schedule.schedule_name) {
            this.errField.set('schedule' + index + 'schedule_name', true);
          }
          if (!schedule.hall_name) {
            this.errField.set('schedule' + index + 'hall_name', true);
          }
        }
      }
      if (schedule.schedule_name) {
        if (!schedule.hall_name) {
          msgs.push('日程(' + schedule.schedule_name + ')の式場名');
          this.errField.set('schedule' + index + 'hall_name', true);
        }
        if (!schedule.schedule_date) {
          msgs.push('日程(' + schedule.schedule_name + ')の日付');
          this.errField.set('schedule' + index + 'schedule_date', true);
        }
        if (!schedule.begin_time) {
          msgs.push('日程(' + schedule.schedule_name + ')の開始時間');
          this.errField.set('schedule' + index + 'begin_time', true);
        }
        // if (!schedule.end_time) {
        //   msgs.push('日程(' + schedule.schedule_name + ')の終了時間');
        // }
      }
      index++;
    }
    if (!this.seko_info.seko_department.id) {
      msgs.push('施行拠点');
      this.errField.set('seko_department', true);
    }
    if (!this.seko_info.seko_style) {
      msgs.push('施行形式');
      this.errField.set('seko_style', true);
    }
    if (!this.seko_info.seko_style_name) {
      msgs.push('施行形式表示名');
      this.errField.set('seko_style_name', true);
    }
    if (!this.seko_info.fuho_site_end_date) {
      msgs.push('訃報ページ掲載終了日');
      this.errField.set('fuho_site_end_date', true);
    }
    if (!this.seko_info.fuho_sentence) {
      msgs.push('訃報文');
      this.errField.set('fuho_sentence', true);
    }
    if (!this.seko_info.fuho_contact_name) {
      msgs.push('お問い合わせ先');
      this.errField.set('fuho_contact_name', true);
    }
    if (!this.seko_info.fuho_contact_tel) {
      msgs.push('お問い合わせ先電話番号');
      this.errField.set('fuho_contact_tel', true);
    }

    // if (!this.seko_info.henrei_kakegami.omotegaki) {
    //   msgs.push('返礼品掛け紙の表書き');
    //   this.errField.set('omotegaki', true);
    // }
    // if (!this.seko_info.henrei_kakegami.mizuhiki) {
    //   msgs.push('返礼品掛け紙の水引');
    //   this.errField.set('mizuhiki', true);
    // }
    // if (!this.seko_info.henrei_kakegami.package_type_name) {
    //   msgs.push('返礼品掛け紙の包装');
    //   this.errField.set('package_type_name', true);
    // }
    // if (!this.seko_info.henrei_kakegami.okurinushi_name) {
    //   msgs.push('返礼品掛け紙の送り主');
    //   this.errField.set('okurinushi_name', true);
    // }

    if (!this.err_tab) {
      this.err_tab = 'accessory-info';
    }

    if (msgs.length > 0) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      this.sessionSvc.save('message', message);
      return false;
    }
    return true;

  }

  setMoshuInfoToSekoContact() {
    this.seko_info.seko_contact.name = this.seko_info.moshu.name;
    this.seko_info.seko_contact.kojin_relationship = this.seko_info.moshu.kojin_moshu_relationship;
    this.seko_info.seko_contact.zip_code = this.seko_info.moshu.zip_code;
    this.seko_info.seko_contact.prefecture = this.seko_info.moshu.prefecture;
    this.seko_info.seko_contact.address_1 = this.seko_info.moshu.address_1;
    this.seko_info.seko_contact.address_2 = this.seko_info.moshu.address_2;
    this.seko_info.seko_contact.address_3 = this.seko_info.moshu.address_3;
    this.seko_info.seko_contact.tel = this.seko_info.moshu.tel;
    this.seko_info.seko_contact.mobile_num = this.seko_info.moshu.mobile_num;
    this.seko_info.seko_contact.mail_address = this.seko_info.moshu.mail_address;

  }
  getSekoPostData(): SekoPostRequest {
    const postRequest = new SekoPostRequest();
    postRequest.seko_department = this.seko_info.seko_department.id;
    postRequest.seko_company = this.seko_info.seko_company.id;
    postRequest.soke_name = this.seko_info.soke_name;
    postRequest.soke_kana = this.seko_info.soke_kana;
    postRequest.seko_style = this.seko_info.seko_style.id;
    postRequest.seko_style_name = this.seko_info.seko_style_name;
    postRequest.fuho_site_end_date = formatDate(this.seko_info.fuho_site_end_date, 'yyyy-MM-dd', this.locale);
    postRequest.fuho_sentence = this.seko_info.fuho_sentence;
    postRequest.fuho_contact_name = this.seko_info.fuho_contact_name;
    postRequest.fuho_contact_tel = this.seko_info.fuho_contact_tel;
    postRequest.note = this.seko_info.note;
    postRequest.order_staff = this.seko_info.order_staff;
    postRequest.seko_staff = this.seko_info.seko_staff;
    postRequest.af_staff = this.seko_info.af_staff;
    postRequest.del_flg = this.seko_info.del_flg;
    postRequest.seko_company = this.login_company.id;
    postRequest.free1_label = this.seko_info.free1_label;
    postRequest.free1_data = this.seko_info.free1_data;
    postRequest.free2_label = this.seko_info.free2_label;
    postRequest.free2_data = this.seko_info.free2_data;
    postRequest.free3_label = this.seko_info.free3_label;
    postRequest.free3_data = this.seko_info.free3_data;
    postRequest.free4_label = this.seko_info.free4_label;
    postRequest.free4_data = this.seko_info.free4_data;
    postRequest.moshu_display_place = this.seko_info.moshu_display_place;
    postRequest.seireki_display_flg = this.seko_info.seireki_display_flg;
    postRequest.attached_file1_name = this.seko_info.attached_file1_name;
    postRequest.attached_file2_name = this.seko_info.attached_file2_name;
    postRequest.attached_file3_name = this.seko_info.attached_file3_name;

    postRequest.moshu = new MoshuForPost();
    postRequest.moshu.name = this.seko_info.moshu.name;
    postRequest.moshu.kana = this.seko_info.moshu.kana;
    postRequest.moshu.kojin_moshu_relationship = this.seko_info.moshu.kojin_moshu_relationship;
    postRequest.moshu.zip_code = this.seko_info.moshu.zip_code;
    postRequest.moshu.prefecture = this.seko_info.moshu.prefecture;
    postRequest.moshu.address_1 = this.seko_info.moshu.address_1;
    postRequest.moshu.address_2 = this.seko_info.moshu.address_2;
    postRequest.moshu.address_3 = this.seko_info.moshu.address_3;
    if (this.seko_info.moshu.tel) {
      postRequest.moshu.tel = this.seko_info.moshu.tel;
    } else {
      postRequest.moshu.tel = null;
    }
    if (this.seko_info.moshu.mobile_num) {
      postRequest.moshu.mobile_num = this.seko_info.moshu.mobile_num;
    } else {
      postRequest.moshu.mobile_num = null;
    }
    postRequest.moshu.mail_address = this.seko_info.moshu.mail_address;
    postRequest.moshu.soke_site_del_flg = !!this.seko_info.moshu.soke_site_del_flg;
    postRequest.moshu.mail_flg = !this.seko_info.moshu.mail_not_flg;

    postRequest.seko_contact = new SekoContactForPost();
    postRequest.seko_contact.name = this.seko_info.seko_contact.name;
    postRequest.seko_contact.kojin_relationship = this.seko_info.seko_contact.kojin_relationship;
    postRequest.seko_contact.zip_code = this.seko_info.seko_contact.zip_code;
    postRequest.seko_contact.prefecture = this.seko_info.seko_contact.prefecture;
    postRequest.seko_contact.address_1 = this.seko_info.seko_contact.address_1;
    postRequest.seko_contact.address_2 = this.seko_info.seko_contact.address_2;
    postRequest.seko_contact.address_3 = this.seko_info.seko_contact.address_3;
    postRequest.seko_contact.tel = this.seko_info.seko_contact.tel;
    postRequest.seko_contact.mobile_num = this.seko_info.seko_contact.mobile_num;
    postRequest.seko_contact.mail_address = this.seko_info.seko_contact.mail_address;
    let index = 1;
    postRequest.kojin = new Array();
    for (const kojinData of this.seko_info.kojin) {
      const kojinPostData = new KojinForPost();
      if (kojinData.id) {
        kojinPostData.id = kojinData.id;
      }
      kojinPostData.kojin_num = index;
      kojinPostData.name = kojinData.name;
      kojinPostData.kana = kojinData.kana;
      kojinPostData.kaimyo = kojinData.kaimyo;
      if (kojinData.iei_file) {
        kojinPostData.iei_file_name = kojinData.iei_file;
      }
      kojinPostData.moshu_kojin_relationship = kojinData.moshu_kojin_relationship;
      kojinPostData.age_kbn = kojinData.age_kbn;
      kojinPostData.age = kojinData.age;
      kojinPostData.note = kojinData.note;
      kojinPostData.del_flg = kojinData.del_flg;
      kojinPostData.birth_date = formatDate(kojinData.birth_date, 'yyyy-MM-dd', this.locale);
      kojinPostData.death_date = formatDate(kojinData.death_date, 'yyyy-MM-dd', this.locale);
      postRequest.kojin.push(kojinPostData);
      if (index === 1) {
        postRequest.death_date = formatDate(kojinData.death_date, 'yyyy-MM-dd', this.locale);
      }
      index++;
    }

    this.seko_info.schedules.sort((a, b) => a.display_num - b.display_num);
    index = 1;
    postRequest.schedules = new Array();
    for (const schedule of this.seko_info.schedules) {
      if (schedule.schedule_name) {
        const sekoSchedulePostData = new SekoScheduleForPost();
        if (schedule.schedule) {
          sekoSchedulePostData.schedule = schedule.schedule.id;
        } else {
          sekoSchedulePostData.schedule = null;
        }
        sekoSchedulePostData.schedule_name = schedule.schedule_name;
        if (schedule.hall) {
          sekoSchedulePostData.hall = schedule.hall.id;
        } else {
          sekoSchedulePostData.hall = null;
        }
        sekoSchedulePostData.hall_name = schedule.hall_name;
        sekoSchedulePostData.hall_zip_code = schedule.hall_zip_code;
        sekoSchedulePostData.hall_address = schedule.hall_address;
        sekoSchedulePostData.hall_tel = schedule.hall_tel;
        sekoSchedulePostData.schedule_date = formatDate(schedule.schedule_date, 'yyyy-MM-dd', this.locale);
        sekoSchedulePostData.begin_time = formatDate(schedule.begin_time, 'HH:mm:ss', this.locale);
        if (schedule.end_time) {
          sekoSchedulePostData.end_time = formatDate(schedule.end_time, 'HH:mm:ss', this.locale);
        } else {
          sekoSchedulePostData.end_time = null;
        }
        sekoSchedulePostData.display_num = index;
        if (schedule.schedule && schedule.schedule.required_flg) {
          postRequest.seko_date = formatDate(schedule.schedule_date, 'yyyy-MM-dd', this.locale);
        }
        postRequest.schedules.push(sekoSchedulePostData);
        index++;
      }
    }
    postRequest.services = new Array();
    postRequest.henreihin_rate = this.seko_info.henreihin_rate;
    if (this.service_list) {
      for (const service of this.service_list) {
        if (service.selected) {
          const seko_service = new ServiceForPost();
          seko_service.hall_service = service.id;
          seko_service.henreihin_select_flg = !!service.henreihin_select_flg;
          const service_term = this.service_reception_term_list.find(v => v.service === service.id);
          if (service_term) {
            let schedule = this.seko_info.schedules.find(v => v.schedule && v.schedule.id === service_term.schedule);
            if (!schedule || !schedule.schedule_name) {
              schedule = this.seko_info.schedules.find(v => v.schedule.id === this.Const.SCHEDULE_ID_KOKUBETSU);
            }
            if (schedule && schedule.schedule_name) {
              const limit_ts = new Date(schedule.schedule_date);
              limit_ts.setHours(0);
              limit_ts.setMinutes(0);
              limit_ts.setSeconds(0);
              if (service_term.unit === this.Const.SERVICE_RECEPTION_UNIT_DAY) {
                limit_ts.setDate(limit_ts.getDate() - service_term.limit_hour);
                limit_ts.setHours(service_term.limit_time);
              } else {
                limit_ts.setMinutes(schedule.begin_time.getMinutes());
                limit_ts.setHours(schedule.begin_time.getHours());
                limit_ts.setHours(limit_ts.getHours() - service_term.limit_hour);
              }
              seko_service.limit_ts = formatDate(limit_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
            }
          }
          postRequest.services.push(seko_service);
        }
      }
    }

    if (this.seko_info.invoice_file) {
      postRequest.invoice_file_name = this.seko_info.invoice_file.file;
    } else if (!this.seko_info.invoice_file_name) {
      postRequest.invoice_file_name = null;
    }
    if (this.seko_info.attached_file1_buf) {
      postRequest.attached_file1 = this.seko_info.attached_file1_buf.file;
    } else if (!this.seko_info.attached_file1) {
      postRequest.attached_file1 = null;
    }
    if (this.seko_info.attached_file2_buf) {
      postRequest.attached_file2 = this.seko_info.attached_file2_buf.file;
    } else if (!this.seko_info.attached_file2) {
      postRequest.attached_file2 = null;
    }
    if (this.seko_info.attached_file3_buf) {
      postRequest.attached_file3 = this.seko_info.attached_file3_buf.file;
    } else if (!this.seko_info.attached_file3) {
      postRequest.attached_file3 = null;
    }
    postRequest.henrei_kakegami = this.seko_info.henrei_kakegami;

    postRequest.videos = new Array();

    for (const video of this.seko_info.videos) {
      const videoPostData = new VideoForPost();
      videoPostData.title = video.title;
      videoPostData.youtube_code = video.youtube_code;
      if (video.live_begin_ts) {
        videoPostData.live_begin_ts = formatDate(video.live_begin_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
        videoPostData.live_end_ts = formatDate(video.live_end_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
      }
      videoPostData.delivery_end_ts = formatDate(video.delivery_end_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
      postRequest.videos.push(videoPostData);
    }
    postRequest.items = new Array();
    for (const item of this.item_list) {
      if (item.service === this.Const.SERVICE_ID_CHOBUN && this.haveService(this.Const.SERVICE_ID_CHOBUN)) {
        const seko_item = new ItemForPost();
        seko_item.item = item.id;
        seko_item.seko_before_flg = true;
        seko_item.seko_after_flg = false;
        postRequest.items.push(seko_item);
      } else if (item.service === this.Const.SERVICE_ID_KUMOTSU &&
        this.haveService(this.Const.SERVICE_ID_KUMOTSU) && (item.selected1 || item.selected2)) {
        const seko_item = new ItemForPost();
        seko_item.item = item.id;
        seko_item.seko_before_flg = item.selected1;
        seko_item.seko_after_flg = item.selected2;
        postRequest.items.push(seko_item);
      } else if (item.service === this.Const.SERVICE_ID_HENREIHIN &&
        this.haveService(this.Const.SERVICE_ID_HENREIHIN) && item.selected1) {
        const seko_item = new ItemForPost();
        seko_item.item = item.id;
        seko_item.seko_before_flg = true;
        seko_item.seko_after_flg = false;
        postRequest.items.push(seko_item);
      } else if (item.service === this.Const.SERVICE_ID_KODEN && this.haveService(this.Const.SERVICE_ID_KODEN)) {
        const seko_item = new ItemForPost();
        seko_item.item = item.id;
        seko_item.seko_before_flg = true;
        seko_item.seko_after_flg = false;
        postRequest.items.push(seko_item);
      } else if (item.service === this.Const.SERVICE_ID_MESSAGE && this.haveService(this.Const.SERVICE_ID_MESSAGE)) {
        const seko_item = new ItemForPost();
        seko_item.item = item.id;
        seko_item.seko_before_flg = true;
        seko_item.seko_after_flg = false;
        postRequest.items.push(seko_item);
      }
    }
    return postRequest;
  }

  async saveAlbum() {
    if (!this.seko_id) {
      return;
    }
    this.albumApiError = false;
    let i = 0;
    for (const album of this.albumItems) {
      i++;
      if (!album.id) {
        const albumPost = new AlbumPostRequest();
        albumPost.file_name = album.image_file;
        albumPost.display_num = i;
        await this.httpClientService.addAlbum(this.seko_id, albumPost)
          .then((response) => {
            Utils.log(response);
            album.id = response.id;
            album.file_name = response.file_name;
            album.display_num = response.display_num;
            album.image_file = null;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'アルバム情報の登録が失敗しました。' });
            this.albumApiError = true;
            this.loaderSvc.dismiss();
          });
      } else {
        if (album.display_num === i) {
          continue;
        }
        const albumPut = new AlbumPutRequest();
        albumPut.display_num = i;
        await this.httpClientService.updateAlbum(this.seko_id, album.id, albumPut)
          .then((response) => {
            Utils.log(response);
            album.file_name = response.file_name;
            album.display_num = response.display_num;
            album.image_file = null;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'アルバム情報の更新が失敗しました。' });
            this.albumApiError = true;
            this.loaderSvc.dismiss();
          });

      }
    }
    if (!this.albumDeletedItems) {
      return;
    }
    for (const album_id of this.albumDeletedItems) {
      await this.httpClientService.deleteAlbum(this.seko_id, album_id)
        .then((response) => {
          Utils.log(response);
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'アルバム情報の削除が失敗しました。' });
          this.albumApiError = true;
          this.loaderSvc.dismiss();
        });
    }
    this.albumDeletedItems = null;

  }

  calcHoyoPagination() {
    this.hoyo_pagination.pages = new Array();
    this.hoyo_pagination.current = 0;
    if (!this.seko_info || !this.seko_info.hoyo_seko || !this.seko_info.hoyo_seko.length) {
      return;
    }
    const count = Math.ceil(this.seko_info.hoyo_seko.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.hoyo_pagination.pages.push(i);
    }
    this.hoyo_pagination.current = 1;
  }

  hoyoPageTo(page_num) {
    this.hoyo_pagination.current = page_num;
  }

  hoyoHallChange(event) {
    if (event.data) {
      for (const schedule of this.hoyo_edit.schedules) {
        if (!schedule.schedule_name) {
          schedule.hall.id = event.data.id;
          schedule.hall_name = event.data.base_name;
        }
      }
    }
  }
  hoyoScheduleNameChange(event, hoyoSchedule) {
    if (!hoyoSchedule.schedule_name) {
      hoyoSchedule.hall.id = this.hoyo_edit.hall.id;
      hoyoSchedule.hall_name = this.hoyo_edit.hall.base_name;
    }

  }

  showHoyoData(event, hoyo) {
    this.message = null;
    if (!hoyo) {
      this.hoyo_edit = new HoyoGetResponse();
      this.hoyo_edit.seshu_name = this.seko_info.moshu.name;
      this.hoyo_edit.kojin_seshu_relationship = this.seko_info.moshu.kojin_moshu_relationship;
      this.hoyo_edit.zip_code = this.seko_info.moshu.zip_code;
      this.hoyo_edit.prefecture = this.seko_info.moshu.prefecture;
      this.hoyo_edit.address_1 = this.seko_info.moshu.address_1;
      this.hoyo_edit.address_2 = this.seko_info.moshu.address_2;
      this.hoyo_edit.address_3 = this.seko_info.moshu.address_3;
      this.hoyo_edit.tel = this.seko_info.moshu.tel;
      this.hoyo_edit.hall = new SekoHall();
      this.hoyo_edit.staff = new StaffGetResponse();
      this.hoyo_edit.staff.id = this.login_info.staff.id;
      this.hoyo_edit.schedules = new Array();
      for (let i = 0; i < 4; i++) {
        const schedule = new HoyoScheduleForGet();
        schedule.hall = new SekoHall();
        this.hoyo_edit.schedules.push(schedule);
      }
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.hoyo_edit = JSON.parse(JSON.stringify(hoyo));
      let cnt = 0;
      if (!this.hoyo_edit.hall) {
        this.hoyo_edit.hall = new SekoHall();
      }
      if (!this.hoyo_edit.staff) {
        this.hoyo_edit.staff = new StaffGetResponse();
        this.hoyo_edit.staff.id = this.login_info.staff.id;
      }
      if (!this.hoyo_edit.schedules) {
        this.hoyo_edit.schedules = new Array();
      } else {
        cnt = this.hoyo_edit.schedules.length;
      }
      for (const schedule of this.hoyo_edit.schedules) {
        if (!schedule.hall) {
          schedule.hall = new SekoHall();
        }
      }
      for (let i = 0; i < 4 - cnt; i++) {
        const schedule = new HoyoScheduleForGet();
        schedule.hall = new SekoHall();
        schedule.hall.id = this.hoyo_edit.hall.id;
        schedule.hall_name = this.hoyo_edit.hall.base_name;
        this.hoyo_edit.schedules.push(schedule);
      }
    }
    $('#hoyo-edit').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    setTimeout(() => {
      $('.ui.toggle.checkbox.modal').checkbox();
    }, 100);
  }

  saveHoyoData() {
    this.sessionSvc.clear('message');
    if (!this.hoyoDataValidate()) {
      return;
    }
    const postData = this.getHoyoPostData();
    this.loaderSvc.call();
    if (this.hoyo_edit.id) {
      this.httpClientService.updateHoyo(this.hoyo_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.saveKiamyo();
          await this.getSekoInfo();
          this.sessionSvc.save('message', { type: 'info', title: '法要情報を保存しました。' });
          this.loaderSvc.dismiss();
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '法要情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    } else {
      this.httpClientService.addHoyo(postData)
        .then(async (response) => {
          Utils.log(response);
          await this.saveKiamyo();
          await this.getSekoInfo();
          this.sessionSvc.save('message', { type: 'info', title: '法要情報を保存しました。' });
          this.loaderSvc.dismiss();
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '法要情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
    $('#hoyo-edit').modal('hide');
    setTimeout(() => {
      this.hoyo_edit = null;
    }, 200);

  }

  async saveKiamyo() {
    if (!this.seko_info.kojin) {
      return;
    }
    for (const kojin of this.seko_info.kojin) {
      const postKojin = new KojinPutRequest();
      postKojin.kaimyo = kojin.kaimyo ? kojin.kaimyo : null;
      await this.httpClientService.updateKojin(this.seko_info.id, kojin.id, postKojin)
        .then(async (response) => {
          Utils.log(response);
        })
        .catch(error => {
        });

    }
  }

  closeHoyoData() {
    setTimeout(() => {
      this.hoyo_edit = null;
    }, 200);
  }

  hoyoDataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.hoyo_edit.hoyo_name) {
      msgs.push('法要名');
    }
    if (!this.hoyo_edit.hoyo_sentence) {
      msgs.push('案内文');
    }
    if (!this.hoyo_edit.hoyo_activity_date) {
      msgs.push('法要日');
    }
    if (!this.hoyo_edit.begin_time) {
      msgs.push('法要開始時間');
    }
    if (!this.hoyo_edit.reply_limit_date) {
      msgs.push('返信期限日');
    }
    if (!this.hoyo_edit.hall.id) {
      msgs.push('式場');
    }
    if (!this.hoyo_edit.hoyo_site_end_date) {
      msgs.push('法要ページ掲載終了日');
    }

    for (const schedule of this.hoyo_edit.schedules) {
      if (schedule.schedule_name) {
        schedule.schedule_name = schedule.schedule_name.trim();
      }
      if (schedule.schedule_name) {
        if (!schedule.hall_name) {
          msgs.push('式次第(' + schedule.schedule_name + ')の式場名');
        }
        if (!schedule.begin_time) {
          msgs.push('式次第(' + schedule.schedule_name + ')の開始時間');
        }
      }
    }
    if (!this.hoyo_edit.seshu_name) {
      msgs.push('施主');
    }
    if (!this.hoyo_edit.kojin_seshu_relationship) {
      msgs.push('故人との関係');
    }
    if (!this.hoyo_edit.zip_code) {
      msgs.push('郵便番号');
    }
    if (!this.hoyo_edit.prefecture || !this.hoyo_edit.address_1) {
      msgs.push('住所');
    }
    if (!this.hoyo_edit.tel) {
      msgs.push('電話番号');
    }
    if (!this.hoyo_edit.staff.id) {
      msgs.push('担当者');
    }
    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    const sentence_rows = this.hoyo_edit.hoyo_sentence.split('\n');
    if (sentence_rows.length > this.hoyo_sentence_max_row) {
      this.message = { type: 'warning', title: '案内文は' + this.hoyo_sentence_max_row + '行以内で入力してください。' };
      return false;
    }
    for (const sentence_row of sentence_rows) {
      if (sentence_row.length > this.hoyo_sentence_max_row_len) {
        this.message = { type: 'warning', title: '案内文は1行に' + this.hoyo_sentence_max_row_len + '文字以内で入力してください。' };
        return false;
      }
    }
    return true;
  }

  getHoyoPostData(): HoyoPostRequest {
    const postRequest = new HoyoPostRequest();
    postRequest.id = this.hoyo_edit.id;
    postRequest.seko = this.seko_id;
    if (this.hoyo_edit.hoyo && this.hoyo_edit.hoyo.id) {
      postRequest.hoyo = this.hoyo_edit.hoyo.id;
    }
    postRequest.hoyo_name = this.hoyo_edit.hoyo_name;
    // postRequest.hoyo_planned_date = formatDate(this.hoyo_edit.hoyo_planned_date, 'yyyy-MM-dd', this.locale);
    postRequest.hoyo_activity_date = formatDate(this.hoyo_edit.hoyo_activity_date, 'yyyy-MM-dd', this.locale);
    postRequest.begin_time = formatDate(this.hoyo_edit.begin_time, 'HH:mm:ss', this.locale);
    if (this.hoyo_edit.end_time) {
      postRequest.end_time = formatDate(this.hoyo_edit.end_time, 'HH:mm:ss', this.locale);
    } else {
      postRequest.end_time = null;
    }
    postRequest.hall = this.hoyo_edit.hall.id;
    postRequest.dine_flg = !!this.hoyo_edit.dine_flg;
    postRequest.seshu_name = this.hoyo_edit.seshu_name;
    postRequest.kojin_seshu_relationship = this.hoyo_edit.kojin_seshu_relationship;
    postRequest.zip_code = this.hoyo_edit.zip_code;
    postRequest.prefecture = this.hoyo_edit.prefecture;
    postRequest.address_1 = this.hoyo_edit.address_1;
    postRequest.address_2 = this.hoyo_edit.address_2;
    postRequest.address_3 = this.hoyo_edit.address_3;
    postRequest.tel = this.hoyo_edit.tel;
    postRequest.hoyo_sentence = this.hoyo_edit.hoyo_sentence;
    postRequest.shishiki_name = this.hoyo_edit.shishiki_name;
    postRequest.reply_limit_date = formatDate(this.hoyo_edit.reply_limit_date, 'yyyy-MM-dd', this.locale);
    postRequest.hoyo_site_end_date = formatDate(this.hoyo_edit.hoyo_site_end_date, 'yyyy-MM-dd', this.locale);
    postRequest.note = this.hoyo_edit.note;
    postRequest.staff = this.hoyo_edit.staff.id;
    postRequest.schedules = new Array();
    let index = 1;
    for (const schedule of this.hoyo_edit.schedules) {
      if (schedule.schedule_name) {
        const schedulePostData = new HoyoScheduleForPost();
        schedulePostData.schedule_name = schedule.schedule_name;
        if (schedule.hall.id) {
          schedulePostData.hall = schedule.hall.id;
        } else {
          schedulePostData.hall = null;
        }
        schedulePostData.hall_name = schedule.hall_name;
        schedulePostData.schedule_date = formatDate(this.hoyo_edit.hoyo_activity_date, 'yyyy-MM-dd', this.locale);
        schedulePostData.begin_time = formatDate(schedule.begin_time, 'HH:mm:ss', this.locale);
        if (schedule.end_time) {
          schedulePostData.end_time = formatDate(schedule.end_time, 'HH:mm:ss', this.locale);
        } else {
          schedulePostData.end_time = null;
        }
        schedulePostData.display_num = index;
        postRequest.schedules.push(schedulePostData);
        index++;
      }
    }
    return postRequest;
  }

  deleteHoyoData(hoyo): void {
    if (!hoyo) {
      return;
    }
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '法要情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteHoyo(hoyo.id)
          .then(async (response) => {
            Utils.log(response);
            await this.getSekoInfo();
            this.sessionSvc.save('message', { type: 'info', title: '法要情報を削除しました。' });
            this.loaderSvc.dismiss();
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '法要情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  downloadHoyoPDF(hoyo, popup = false) {
    if (hoyo) {
      this.message = null;
      this.httpClientService.downloadHoyoPdf(hoyo.id)
        .then((response) => {
          const file_name = '法要案内状-' + hoyo.id + '.pdf';
          this.localStreamDownload(response, file_name);
        }).catch(error => {
          if (popup) {
            this.message = { type: 'error', title: '法要案内状PDFの出力が失敗しました。' };
          } else {
            this.sessionSvc.save('message', { type: 'error', title: '法要案内状PDFの出力が失敗しました。' });
          }
        });
    }
  }

  canDownloadHoyoPdf(hoyo) {
    if (!hoyo || !hoyo.id || !this.seko_info.hoyo_seko) {
      return false;
    }
    const hoyo_temp = this.seko_info.hoyo_seko.find(v => v.id === hoyo.id);
    if (!hoyo_temp || !hoyo_temp.hoyo_activity_date) {
      return false;
    }
    return true;

  }

  async getSekoAf() {
    if (!this.seko_id) {
      return;
    }
    await this.httpClientService.getSekoByid(this.seko_id).then(async (sekoResponse) => {
      Utils.formatSekoDate(sekoResponse);
      Utils.log(sekoResponse);
      this.seko_info.seko_af = sekoResponse.seko_af;
      setTimeout(() => {
        $('.ui.answered.checkbox').checkbox();
      }, 100);

    }).catch(error => {
      return;
    });
  }

  async saveAfAnswered() {
    this.message = null;
    this.sessionSvc.clear('message');
    if (!this.seko_info.seko_af || !this.seko_info.seko_af.wishes || !this.seko_info.seko_af.wishes.length) {
      return;
    }
    this.loaderSvc.call();
    let ret = true;
    for (const wish of this.seko_info.seko_af.wishes) {
      const postData = new AfWishForPost();
      postData.answered_flg = wish.answered_flg;

      await this.httpClientService.updateAfWish(wish.id, postData)
        .then(async (response) => {
          Utils.log(response);
        })
        .catch(error => {
          ret = false;
          return;
        });
    }
    if (ret) {
      await this.getSekoAf();
      this.sessionSvc.save('message', { type: 'info', title: 'AF回答状況を保存しました。' });
      this.loaderSvc.dismiss();
    } else {
      this.sessionSvc.save('message', { type: 'error', title: 'AF回答状況の保存が失敗しました。' });
      this.loaderSvc.dismiss();
    }

  }

  showAfWishData(event, af_wish) {
    this.message = null;
    if (this.af_wish_edit) {
      return;
    }
    if (!af_wish) {
      return;
    } else {
      if (event.target.classList.contains('checkbox_answered')) {
        return;
      }
      this.af_wish_edit = JSON.parse(JSON.stringify(af_wish));
      this.af_wish_edit.proposed_flg = (this.af_wish_edit.proposal_status === this.Const.PROPOSAL_STATUS_ID_PROPOSED);
    }
    $('#af-wish-edit').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    setTimeout(() => {
      $('.ui.proposed.checkbox').checkbox();
    }, 100);
  }

  closeAfWishData() {
    setTimeout(() => {
      this.af_wish_edit = null;
    }, 100);
  }

  async saveAfWishData() {
    this.message = null;
    const postData = new AfWishForPost();
    if (this.af_wish_edit.proposed_flg) {
      postData.proposal_status = this.Const.PROPOSAL_STATUS_ID_PROPOSED;
    } else {
      postData.proposal_status = this.Const.PROPOSAL_STATUS_ID_NONE;
    }
    if (!isNullOrUndefined(this.af_wish_edit.order_status)) {
      postData.order_status = this.af_wish_edit.order_status;
    } else {
      postData.order_status = null;
    }
    if (!isNullOrUndefined(this.af_wish_edit.order_chance)) {
      postData.order_chance = this.af_wish_edit.order_chance;
    } else {
      postData.order_chance = null;
    }
    if (!isNullOrUndefined(this.af_wish_edit.order_date)) {
      postData.order_date = formatDate(this.af_wish_edit.order_date, 'yyyy-MM-dd', this.locale);
    } else {
      postData.order_date = null;
    }
    $('#af-wish-edit').modal('hide');

    this.loaderSvc.call();
    await this.httpClientService.updateAfWish(this.af_wish_edit.id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.getSekoAf();
        this.sessionSvc.save('message', { type: 'info', title: 'AF活動状況を保存しました。' });
        this.loaderSvc.dismiss();
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: 'AF活動状況の保存が失敗しました。' });
        this.sessionSvc.clear('message');
        this.loaderSvc.dismiss();
      });
    setTimeout(() => {
      this.af_wish_edit = null;
    }, 100);

  }

  showAfActivityData(event, af_activity = null) {
    this.message = null;
    this.message_sub = null;
    if (this.af_activity_edit) {
      return;
    }
    if (!af_activity) {
      this.af_activity_edit = new AfActivityForGet();
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.af_activity_edit = JSON.parse(JSON.stringify(af_activity));
    }
    $('#af-activity-edit').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
    setTimeout(() => {
      $('.ui.proposed.checkbox').checkbox();
    }, 100);
  }

  closeAfActivityData() {
    setTimeout(() => {
      this.af_activity_edit = null;
    }, 100);
  }

  async saveAfActivityData() {
    this.message = null;
    this.sessionSvc.clear('message');
    if (!this.afActivityValidate()) {
      return;
    }
    const postData = new AfActivityForPost();
    postData.activity_ts = formatDate(this.af_activity_edit.activity_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
    postData.activity = this.af_activity_edit.activity;
    postData.af_wish = this.af_wish_edit.id;

    $('#af-activity-edit').modal('hide');
    this.loaderSvc.call();
    if (this.af_activity_edit.id) {
      await this.httpClientService.updateAfActivity(this.af_activity_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getSekoAf();
          const af_wish = this.seko_info.seko_af.wishes.find(v => v.id === this.af_wish_edit.id);
          this.af_wish_edit.activities = JSON.parse(JSON.stringify(af_wish.activities));
          this.message = { type: 'info', title: 'AF活動内容を保存しました。' };
          this.loaderSvc.dismiss();
        })
        .catch(error => {
          this.message = { type: 'error', title: 'AF活動内容の保存が失敗しました。' };
          this.loaderSvc.dismiss();
        });
    } else {
      await this.httpClientService.addAfActivity(postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getSekoAf();
          const af_wish = this.seko_info.seko_af.wishes.find(v => v.id === this.af_wish_edit.id);
          this.af_wish_edit.activities = JSON.parse(JSON.stringify(af_wish.activities));
          this.message = { type: 'info', title: 'AF活動内容を保存しました。' };
          this.loaderSvc.dismiss();
        })
        .catch(error => {
          this.message = { type: 'error', title: 'AF活動内容の保存が失敗しました。' };
          this.sessionSvc.clear('message');
          this.loaderSvc.dismiss();
        });
    }
    setTimeout(() => {
      this.af_activity_edit = null;
    }, 100);

  }

  afActivityValidate() {
    const msgs = new Array();
    this.message_sub = null;
    if (!this.af_activity_edit.activity_ts) {
      msgs.push('活動日時');
    }
    this.af_activity_edit.activity = (
      this.af_activity_edit.activity ? this.af_activity_edit.activity.trim() : this.af_activity_edit.activity
    );
    if (!this.af_activity_edit.activity) {
      msgs.push('内容');
    }

    if (msgs.length > 0) {
      this.message_sub = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }
}
