
import { ScheduleResponse, SekoStyleGetResponse, ServiceGetResponse } from '../interfaces/master';
import { ItemGetResponse } from '../interfaces/item';
import { BaseGetResponse } from '../interfaces/base';
import { StaffGetResponse } from '../interfaces/staff';
import { HoyoMasterGetResponse, HoyoMailTargetForGet } from '../interfaces/hoyo';
import { SekoAfGetResponse } from '../interfaces/af';

export class SekoGetRequest {
  seko_company: number;
  seko_department: number;
  seko_style: number;
  hoyo_style: number;
  hall: number;
  soke_name: string;
  soke_kana: string;
  kojin_name: string;
  kojin_kana: string;
  moshu_name: string;
  moshu_kana: string;
  order_staff: number;
  seko_staff: number;
  af_staff: number;
  seko_date_after: string;
  seko_date_before: string;
  death_date_after: string;
  death_date_before: string;
}

class SekoBase {
  soke_name: string;
  soke_kana: string;
  seko_style_name: string;
  fuho_sentence: string;
  fuho_contact_name: string;
  fuho_contact_tel: string;
  henreihin_rate: number;
  invoice_file_name: string;
  attached_file1_name: string;
  attached_file1: string;
  attached_file2_name: string;
  attached_file2: string;
  attached_file3_name: string;
  attached_file3: string;
  note: string;
  order_staff: number;
  seko_staff: number;
  af_staff: number;
  del_flg: boolean;
  free1_label: string;
  free1_data: string;
  free2_label: string;
  free2_data: string;
  free3_label: string;
  free3_data: string;
  free4_label: string;
  free4_data: string;
  moshu_display_place: number;
  seireki_display_flg: boolean;
}
export class SekoGetResponse extends SekoBase {
  id: number;
  death_date: Date;
  seko_date: Date;
  seko_company: BaseGetResponse;
  seko_department: BaseGetResponse;
  fuho_site_end_date: Date;
  fuho_site_admission_ts: Date;
  seko_department_name: string;
  seko_company_name: string;
  order_staff_name: string;
  seko_staff_name: string;
  af_staff_name: string;
  seko_style: SekoStyleGetResponse;
  invoice_file?: any;
  attached_file1_buf?: any;
  attached_file2_buf?: any;
  attached_file3_buf?: any;
  moshu: MoshuForGet;
  seko_contact: SekoContactForGet;
  henrei_kakegami: HenreihinKakegamiForGet;
  kojin: Array<KojinForGet>;
  videos: Array<VideoForGet>;
  schedules: Array<SekoScheduleForGet>;
  albums: Array<AlbumForGet>;
  services: Array<ServiceForGet>;
  items: Array<ItemForGet>;
  hoyo_seko: Array<HoyoGetResponse>;
  seko_af: SekoAfGetResponse;
  inquiries: Array<SekoInquiryGetResponse>;
  hoyo_mail_targets: Array<HoyoMailTargetForGet>;
  selected?: Boolean;
}

export class SekoPostRequest extends SekoBase {
  death_date: string;
  seko_date: string;
  seko_company: number;
  seko_department: number;
  fuho_site_end_date: string;
  seko_style: number;
  moshu: MoshuForPost;
  seko_contact: SekoContactForPost;
  henrei_kakegami: HenreihinKakegamiForPost;
  kojin: Array<KojinForPost>;
  videos: Array<VideoForPost>;
  schedules: Array<SekoScheduleForPost>;
  services: Array<ServiceForPost>;
  items: Array<ItemForPost>;
}

export class SekoPutRequest extends SekoPostRequest {
}

export class SekoPostResponse extends SekoGetResponse {
}
export class SekoPutResponse extends SekoGetResponse {
}
export class SekoDeleteResponse {
}
export class SekoHall {
  id: number;
  base_type: number;
  base_name: string;
  company_map_file: string;

}
export class MoshuGetRequest {
  company: number;
  prefecture: string;
  address_1: string;
  af_type: Array<number>;
}
class MoshuBase {
  name: string;
  kana: string;
  kojin_moshu_relationship: string;
  zip_code: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  address_3: string;
  tel: string;
  mobile_num: string;
  mail_address: string;
  soke_site_del_request_flg: boolean;
  soke_site_del_flg: boolean;
  mail_flg: boolean;
}
export class MoshuForGet extends MoshuBase {
  id: number;
  mail_not_flg?: boolean;
  agree_ts: Date;
}
export class MoshuGetResponse extends MoshuForGet {
  selected?: Boolean;
  seko: SekoGetResponse;
}
export class MoshuForPost extends MoshuBase {
}
export class MoshuPutRequest extends MoshuBase {
  fuho_site_end_date: string;
  password: string;
  seko_contact: SekoContactForPost;
}
export class MoshuPutResponse extends MoshuBase {
}
class SekoContactBase {
  name: string;
  kojin_relationship: string;
  zip_code: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  address_3: string;
  tel: string;
  mobile_num: string;
  mail_address: string;
}
export class SekoContactForGet extends SekoContactBase {
  id: number;
}
export class SekoContactForPost extends SekoContactBase {
  id: number;
}
class HenreihinKakegamiBase {
  omotegaki: string;
  mizuhiki: string;
  package_type_name: string;
  okurinushi_name: string;
}
export class HenreihinKakegamiForGet extends HenreihinKakegamiBase {
  id: number;
}
export class HenreihinKakegamiForPost extends HenreihinKakegamiBase {
}


class KojinBase {
  id: number;
  kojin_num: number;
  name: string;
  kana: string;
  kaimyo: string;
  iei_file_name: string;
  moshu_kojin_relationship: string;
  age_kbn: string;
  age: number;
  note: string;
  del_flg: boolean;
}
export class KojinForGet extends KojinBase {
  iei_file?: any;
  birth_date: Date;
  death_date: Date;
}
export class KojinForPost extends KojinBase {
  birth_date: String;
  death_date: String;
}

class SekoScheduleBase {
  schedule_name: string;
  hall_name: string;
  hall_zip_code: string;
  hall_address: string;
  hall_tel: string;
  display_num: number;
}
export class SekoScheduleForGet extends SekoScheduleBase {
  id: number;
  schedule: ScheduleResponse;
  hall: SekoHall;
  schedule_date: Date;
  begin_time: Date;
  end_time: Date;
}
export class SekoScheduleForPost extends SekoScheduleBase {
  schedule: number;
  hall: number;
  schedule_date: string;
  begin_time: string;
  end_time: string;
}

class VideoBase {
  title: string;
  youtube_code: string;
}
export class VideoForGet extends VideoBase {
  id: number;
  live_begin_ts: Date;
  live_end_ts: Date;
  delivery_end_ts: Date;
  type: number;
}
export class VideoForPost extends VideoBase {
  live_begin_ts: string;
  live_end_ts: string;
  delivery_end_ts: string;
}

class ServiceBase {
  id: number;
  henreihin_select_flg: boolean;
}
export class ServiceForGet extends ServiceBase {
  hall_service: ServiceGetResponse;
  limit_ts: Date;
}
export class ServiceForPost extends ServiceBase {
  hall_service: number;
  limit_ts: String;
}

class ItemBase {
  id: number;
  seko_after_flg: boolean;
  seko_before_flg: boolean;
}
export class ItemForGet extends ItemBase {
  item: ItemGetResponse;
}
export class ItemForPost extends ItemBase {
  item: number;
}

class AlbumBase {
  file_name: string;
  display_num: number;
}
export class AlbumForGet extends AlbumBase {
  id: number;
}
export class AlbumForPost extends AlbumBase {
}

export class AlbumPostRequest extends AlbumBase {
}
export class AlbumPostResponse extends AlbumBase {
  id: number;
}
export class AlbumPutRequest extends AlbumBase {
}
export class AlbumPutResponse extends AlbumBase {
}
export class AlbumDeleteResponse {
}

export class FuhoMailPostRequest {
}
export class FuhoMailPostResponse {
}

export class NoticeAttachedFilePostRequest {
  file_title: string;
}
export class NoticeAttachedFilePostResponse {
}
export class KojinPutRequest extends KojinForPost {
}
export class KojinPutResponse {
}
export class HomeichoGetResponse {
  entry_id: number;
  entry_name: string;
  details: Array<HomeichoDetail>;
}
class HomeichoDetail {
  okurinushi_name: string;
  service_name: string;
  item_name: string;
}

export class HoyoGetRequest {
  seko_company: number;
  seko_department: number;
  hoyo: number;
  hoyo_planned_date_after: string;
  hoyo_planned_date_before: string;
}

class HoyoBase {
  hoyo_name: string;
  dine_flg: boolean;
  seshu_name: string;
  kojin_seshu_relationship: string;
  zip_code: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  address_3: string;
  tel: string;
  hoyo_sentence: string;
  shishiki_name: string;
  note: string;
}
export class HoyoGetResponse extends HoyoBase {
  id: number;
  hoyo: HoyoMasterGetResponse;
  hoyo_planned_date: Date;
  hoyo_activity_date: Date;
  begin_time: Date;
  end_time: Date;
  hall: SekoHall;
  reply_limit_date: Date;
  hoyo_site_end_date: Date;
  staff: StaffGetResponse;
  schedules: Array<HoyoScheduleForGet>;
  seko: SekoGetResponse;
  selected?: boolean;
}

export class HoyoPostRequest extends HoyoBase {
  id: number;
  seko: number;
  hoyo: number;
  hoyo_planned_date: string;
  hoyo_activity_date: string;
  begin_time: string;
  end_time: string;
  hall: number;
  reply_limit_date: string;
  hoyo_site_end_date: string;
  staff: number;
  schedules: Array<HoyoScheduleForPost>;
}

export class HoyoPutRequest extends HoyoPostRequest {
}

export class HoyoPostResponse extends HoyoGetResponse {
}
export class HoyoPutResponse extends HoyoGetResponse {
}
export class HoyoDeleteResponse {
}
class HoyoScheduleBase {
  schedule_name: string;
  display_num: number;
  hall_name: string;
}
export class HoyoScheduleForGet extends HoyoScheduleBase {
  id: number;
  hall: SekoHall;
  schedule_date: Date;
  begin_time: Date;
  end_time: Date;
}
export class HoyoScheduleForPost extends HoyoScheduleBase {
  schedule_date: string;
  hall: number;
  begin_time: string;
  end_time: string;
}
class SekoInquiryBase {
  query_group_id: number;
  query: string;
}
export class SekoInquiryGetResponse extends SekoInquiryBase {
  id: number;
  updated_at: Date;
  answers: Array<SekoAnswerGetResponse>;
}

export class SekoInquiryPostRequest extends SekoInquiryBase {
}

export class SekoInquiryPostResponse extends SekoInquiryGetResponse {
}
class SekoAnswerBase {
  answer: string;
}
export class SekoAnswerGetResponse extends SekoAnswerBase {
  id: number;
  staff: StaffGetResponse;
  updated_at: Date;
}

export class SekoAnswerPostRequest extends SekoAnswerBase {
}

export class SekoAnswerPostResponse extends SekoAnswerGetResponse {
}
