import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Router, NavigationExtras } from '@angular/router';
import { Utils } from '../const/func-util';

import {
  LoginPostRequest, LoginPostResponse, LoginStaffGetResponse,
  SokeLoginPostRequest, SokeLoginPostResponse
} from '../interfaces/login';
import {
  SekoGetRequest, SekoGetResponse,
  SekoPostRequest, SekoPostResponse,
  SekoPutResponse, SekoPutRequest, SekoDeleteResponse,
  AlbumPostRequest, AlbumPostResponse,
  AlbumPutRequest, AlbumPutResponse, AlbumDeleteResponse,
  FuhoMailPostRequest, FuhoMailPostResponse, HomeichoGetResponse,
  KojinPutRequest, KojinPutResponse,
  MoshuPutRequest, MoshuPutResponse,
  HoyoPostRequest, HoyoPostResponse,
  HoyoPutRequest, HoyoPutResponse,
  HoyoDeleteResponse, HoyoGetRequest, HoyoGetResponse,
  SekoInquiryGetResponse, SekoInquiryPostRequest, SekoInquiryPostResponse,
  SekoAnswerPostRequest, SekoAnswerPostResponse,
  MoshuGetRequest, MoshuGetResponse,
  NoticeAttachedFilePostRequest, NoticeAttachedFilePostResponse
} from '../interfaces/seko';
import {
  AddressGetResponse, AddressGetRequest, TaxGetResponse,
  SekoStyleGetResponse, ScheduleResponse, FuhoSampleGetResponse,
  HoyoStyleGetResponse, HoyoDefaultGetResponse, HoyoSampleMasterGetResponse,
  WarekiGetResponse, ChobunDashiGetResponse, ServiceGetResponse,
  ChobunSampleGetResponse, RelationshipResponse,
  HenreihinParameterResponse, KodenParameterResponse, SokeMenuGetResponse
} from '../interfaces/master';
import {
  BaseGetResponse, BasePostRequest, BasePostResponse,
  BasePutRequest, BasePutResponse, BaseDeleteResponse,
  FocFeePostResponse, FocFeePutResponse, FocFeePostRequest, FocFeePutRequest,
  TokushoPostResponse, TokushoPutResponse, TokushoPostRequest, TokushoPutRequest,
  TokushoGetResponse, SmsAccountPostResponse, SmsAccountPutResponse,
  SmsAccountPostRequest, SmsAccountPutRequest, SmsAccountGetResponse
} from '../interfaces/base';
import {
  SupplierGetRequest, SupplierGetResponse, SupplierPostRequest,
  SupplierPostResponse, SupplierPutRequest, SupplierPutResponse,
  SupplierDeleteResponse
} from '../interfaces/supplier';
import {
  ItemGetRequest, ItemGetResponse, ItemPostRequest,
  ItemPostResponse, ItemPutRequest, ItemPutResponse,
  ItemDeleteResponse, ItemImagePostRequest
} from '../interfaces/item';
import {
  CompanyFuhoSampleGetRequest, CompanyFuhoSampleGetResponse, CompanyFuhoSamplePostRequest,
  CompanyFuhoSamplePostResponse, CompanyFuhoSamplePutRequest, CompanyFuhoSamplePutResponse,
  CompanyFuhoSampleDeleteResponse
} from '../interfaces/fuho-sample';
import {
  CompanyChobunDiashiGetRequest, CompanyChobunDiashiGetResponse, CompanyChobunDiashiPostRequest
} from '../interfaces/chobun-daishi';
import {
  CompanyServicePostRequest, ServiceReceptionTermGetRequest,
  ServiceReceptionTermGetResponse, ServiceReceptionTermPostRequest
} from '../interfaces/service';
import {
  StaffGetResponse, StaffPostRequest, StaffPostResponse,
  StaffPutRequest, StaffPutResponse, StaffDeleteResponse
} from '../interfaces/staff';
import {
  EntryPostRequest, EntryPostResponse, EntryGetResponse,
  ChobunGetRequest, ChobunGetResponse, EntryGetResquest,
  ChobunPutRequest, ChobunPutResponse,
  KumotsuGetRequest, KumotsuGetResponse,
  KumotsuPutRequest, KumotsuPutResponse,
  KumotsuOrderStatusPostRequest, KumotsuOrderStatusPostResponse,
  KodenGetRequest, KodenGetResponse,
  MessageGetRequest, MessageGetResponse,
  MessagePutRequest, MessagePutResponse
} from '../interfaces/order';

import {
  HenreihinGetRequest, HenreihinGetResponse,
  HenreihinOrderPostRequest, HenreihinOrderPostResponse,
  HenreihinPutRequest, HenreihinPutResponse,
  HenreihinPostRequest, HenreihinPostResponse,
  OrderHenreiGetRequest, OrderHenreiGetResponse,
  OrderHenreiOrderStatusPostRequest, OrderHenreiOrderStatusPostResponse,
  OrderHenreiPutRequest, OrderHenreiPutResponse,
  HenreiSelectStatusPostRequest, HenreiSelectStatusPostResponse
} from '../interfaces/henreihin';

import {
  SalesGetRequest, SalesGetResponse,
  InvoiceGatherPostRequest, InvoiceGatherPostResponse
} from '../interfaces/invoice';

import {
  InquiryPostRequest, InquiryPostResponse,
  InquiryToCompanyPostRequest, InquiryToCompanyPostResponse
} from '../interfaces/inquiry';
import {
  AfGroupGetRequest, AfGroupGetResponse,
  AfGroupPostRequest, AfGroupPostResponse,
  AfGroupPutRequest, AfGroupPutResponse, AfGroupDeleteResponse,
  AfPostRequest, AfPostResponse,
  AfPutRequest, AfPutResponse, AfDeleteResponse,
  AfContactGetRequest, AfContactGetResponse,
  AfContactPostRequest, AfContactPostResponse,
  AfContactPutRequest, AfContactPutResponse, AfContactDeleteResponse,
  SekoAfPostRequest, SekoAfPostResponse,
  SekoAfPutRequest, SekoAfPutResponse,
  AfWishForGet, AfWishForPost,
  AfActivityForGet, AfActivityForPost
} from '../interfaces/af';
import {
  HoyoMasterGetRequest, HoyoMasterGetResponse,
  HoyoMasterPostRequest, HoyoMasterPostResponse,
  HoyoMasterPutRequest, HoyoMasterPutResponse, HoyoMasterDeleteResponse,
  HoyoSampleGetRequest, HoyoSampleGetResponse,
  HoyoSamplePostRequest, HoyoSamplePostResponse,
  HoyoSamplePutRequest, HoyoSamplePutResponse, HoyoSampleDeleteResponse,
  HoyoMailTemplateGetRequest, HoyoMailTemplateGetResponse,
  HoyoMailTemplatePostRequest, HoyoMailTemplatePostResponse,
  HoyoMailTemplatePutRequest, HoyoMailTemplatePutResponse, HoyoMailTemplateDeleteResponse,
  HoyoMailGetRequest, HoyoMailGetResponse, HoyoMailPostRequest, HoyoMailPostResponse
} from '../interfaces/hoyo';

import {
  EventMailGetRequest, EventMailGetResponse,
  EventMailPostRequest, EventMailPostResponse
} from '../interfaces/event';

import {
  FaqGetRequest, FaqGetResponse, FaqPostRequest, FaqPostResponse,
  FaqPutRequest, FaqPutResponse, FaqDeleteResponse
} from '../interfaces/faq';

import {
  AdvertiseGetRequest, AdvertiseGetResponse, AdvertisePostRequest,
  AdvertisePostResponse, AdvertisePutRequest, AdvertisePutResponse,
  AdvertiseDeleteResponse
} from '../interfaces/advertise';
import { CompanySokeMenuPostRequest } from '../interfaces/soke-menu';
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  // リクエストヘッダ
  private headers: HttpHeaders;

  // API接続先URL
  private host: string = this.configSvc.getConfig().api_url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private configSvc: RuntimeConfigLoaderService,
  ) { }

  // getメソッドメイン(単体)
  private async get<T>(method: string, params: HttpParams): Promise<T> {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.host + method, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // getメソッドメイン(リスト)
  private async getList<T>(method: string, params: HttpParams): Promise<T[]> {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.host + method, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // getメソッドメイン(要ログイン認証)
  private async getWithToken<T>(method: string, params: HttpParams): Promise<T> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    return this.http.get(this.host + method, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // getメソッドメイン(リスト、要ログイン認証)
  private async getListWithToken<T>(method: string, params: HttpParams): Promise<T[]> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    return this.http.get(this.host + method, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // postメソッドメイン
  private async post<T, K>(method: string, body: T): Promise<K> {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    Utils.log(this.host + method);
    Utils.log('Request Body=', body);
    return this.http.post(this.host + method, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // putメソッドメイン
  private async put<T, K>(method: string, body: T): Promise<K> {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(this.host + method, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // postメソッドメイン(要ログイン認証)
  private async postWithToken<T, K>(method: string, body: T): Promise<K> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    // Utils.log(this.host + method + ' token=' + login_info.token.access);
    Utils.log('Request Body=', body);
    return this.http.post(this.host + method, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // putメソッドメイン(要ログイン認証)
  private async putWithToken<T, K>(method: string, body: T): Promise<K> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    // Utils.log(this.host + method + ' token=' + login_info.token.access);
    Utils.log('Request Body=', body);
    return this.http.put(this.host + method, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // patchメソッドメイン(要ログイン認証)
  private async patchWithToken<T, K>(method: string, body: T): Promise<K> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    // Utils.log(this.host + method + ' token=' + login_info.token.access);
    Utils.log('Request Body=', body);
    return this.http.patch(this.host + method, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // patchメソッドメイン(要ログイン認証)
  private async deleteWithToken<T>(method: string, params: HttpParams = null): Promise<T> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': str });
    Utils.log(this.host + method);
    return this.http.delete(this.host + method, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  private async downloadWithToken<T>(method: string): Promise<T> {
    await this.refreshToken();
    if (document.execCommand) {
      document.execCommand('ClearAuthenticationCache', false);
    }
    const login_info = this.getLoginInfo();
    let str = '';
    if (login_info && login_info.token) {
      str = 'Bearer ' + login_info.token.access;
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream', 'Authorization': str });
    return this.http.get(this.host + method, { headers: this.headers, responseType: 'blob' })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  private async download<T>(method: string, params: HttpParams): Promise<T> {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.http.get(this.host + method, { headers: this.headers, params: params, responseType: 'blob' })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  public async downloadImage<T>(url: string): Promise<T> {
    return this.http.get(url, { responseType: 'blob' })
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((err) => {
        this.checkErrorType(err);
        return Promise.reject(err.error || 'error');
      });
  }

  // ログイン認証
  public postLogin(login: LoginPostRequest): Promise<LoginPostResponse> {
    Utils.log(this.host + '/staffs/login/');
    return this.post<LoginPostRequest, LoginPostResponse>('/staffs/login/', login);
  }

  // スタッフ情報取得
  public getLoginStaff(): Promise<LoginStaffGetResponse> {
    Utils.log(this.host + '/staffs/me/');
    const params = new HttpParams();
    return this.getWithToken<LoginStaffGetResponse>('/staffs/me/', params);
  }

  // 全拠点一覧取得
  public getBaseList(): Promise<BaseGetResponse[]> {
    Utils.log(this.host + '/bases/all/');
    const params = new HttpParams();
    return this.getListWithToken<BaseGetResponse>('/bases/all/', params);
  }

  // 拠点取得
  public getBase(id: number): Promise<BaseGetResponse> {
    Utils.log(this.host + '/bases/' + id + '/');
    const params = new HttpParams();
    return this.getWithToken<BaseGetResponse>('/bases/' + id + '/', params);
  }

  // 拠点情報登録
  public async addBase(base: BasePostRequest): Promise<BasePostResponse> {
    Utils.log(this.host + '/bases/');
    return this.postWithToken<BasePostRequest, BasePostResponse>('/bases/', base);
  }

  // 拠点情報更新
  public async updateBase(id: number, base: BasePutRequest): Promise<BasePutResponse> {
    Utils.log(this.host + '/bases/' + id + '/');
    return this.patchWithToken<BasePutRequest, BasePutResponse>('/bases/' + id + '/', base);
  }

  // 拠点情報削除
  public async deleteBase(id: number): Promise<BaseDeleteResponse> {
    Utils.log(this.host + '/bases/' + id + '/');
    return this.deleteWithToken<BaseDeleteResponse>('/bases/' + id + '/');
  }

  // 葬儀社問い合わせメール送信
  public async inquiryToCompany(id: number, base: InquiryToCompanyPostRequest): Promise<InquiryToCompanyPostResponse> {
    Utils.log(this.host + '/bases/' + id + '/inquiry/');
    return this.post<InquiryToCompanyPostRequest, InquiryToCompanyPostResponse>('/bases/' + id + '/inquiry/', base);
  }

  // 利用料情報登録
  public async addFocFee(base_id: number, focfee: FocFeePostRequest): Promise<FocFeePostResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/focfee/');
    return this.postWithToken<FocFeePostRequest, FocFeePostResponse>('/bases/' + base_id + '/focfee/', focfee);
  }

  // 利用料情報更新
  public async updateFocFee(base_id: number, focfee: FocFeePutRequest): Promise<FocFeePutResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/focfee/');
    return this.patchWithToken<FocFeePutRequest, FocFeePutResponse>('/bases/' + base_id + '/focfee/', focfee);
  }

  // 特商法情報取得
  public getTokusho(base_id): Promise<TokushoGetResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/tokusho/');
    const params = new HttpParams();
    return this.get<TokushoGetResponse>('/bases/' + base_id + '/tokusho/', params);
  }

  // 特商法情報登録
  public async addTokusho(base_id: number, tokusho: TokushoPostRequest): Promise<TokushoPostResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/tokusho/');
    return this.postWithToken<TokushoPostRequest, TokushoPostResponse>('/bases/' + base_id + '/tokusho/', tokusho);
  }

  // 特商法情報更新
  public async updateTokusho(base_id: number, tokusho: TokushoPutRequest): Promise<TokushoPutResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/tokusho/');
    return this.patchWithToken<TokushoPutRequest, TokushoPutResponse>('/bases/' + base_id + '/tokusho/', tokusho);
  }

  // SMSアカウント情報取得
  public getSmsAccount(base_id): Promise<SmsAccountGetResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/sms/');
    const params = new HttpParams();
    return this.get<SmsAccountGetResponse>('/bases/' + base_id + '/sms/', params);
  }

  // SMSアカウント情報登録
  public async addSmsAccount(base_id: number, sms: SmsAccountPostRequest): Promise<SmsAccountPostResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/sms/');
    return this.postWithToken<SmsAccountPostRequest, SmsAccountPostResponse>('/bases/' + base_id + '/sms/', sms);
  }

  // SMSアカウント情報更新
  public async updateSmsAccount(base_id: number, sms: SmsAccountPutRequest): Promise<SmsAccountPutResponse> {
    Utils.log(this.host + '/bases/' + base_id + '/sms/');
    return this.patchWithToken<SmsAccountPutRequest, SmsAccountPutResponse>('/bases/' + base_id + '/sms/', sms);
  }


  // 発注先一覧取得
  public getSupplierList(request: SupplierGetRequest): Promise<SupplierGetResponse[]> {
    Utils.log(this.host + '/suppliers/' + '?company=' + request.company_id);
    let params = new HttpParams();
    params = params.set('company', String(request.company_id));
    return this.getListWithToken<SupplierGetResponse>('/suppliers/', params);
  }

  // 発注先情報登録
  public async addSupplier(data: SupplierPostRequest): Promise<SupplierPostResponse> {
    Utils.log(this.host + '/suppliers/');
    return this.postWithToken<SupplierPostRequest, SupplierPostResponse>('/suppliers/', data);
  }

  // 発注先情報更新
  public async updateSupplier(id: number, data: SupplierPutRequest): Promise<SupplierPutResponse> {
    Utils.log(this.host + '/suppliers/' + id + '/');
    return this.patchWithToken<SupplierPutRequest, SupplierPutResponse>('/suppliers/' + id + '/', data);
  }

  // 発注先情報削除
  public async deleteSupplier(id: number): Promise<SupplierDeleteResponse> {
    Utils.log(this.host + '/suppliers/' + id + '/');
    return this.deleteWithToken<SupplierDeleteResponse>('/suppliers/' + id + '/');
  }

  // 商品一覧取得
  public getItemList(request: ItemGetRequest): Promise<ItemGetResponse[]> {
    let url = this.host + '/items/' + '?base=' + request.base_id;
    let params = new HttpParams();
    params = params.set('base', String(request.base_id));
    if (request.service_id) {
      params = params.set('service', String(request.service_id));
      url += '&service=' + request.service_id;
    }
    if (request.hinban) {
      params = params.set('hinban', String(request.hinban));
      url += '&hinban=' + request.hinban;
    }
    Utils.log(url);
    return this.getListWithToken<ItemGetResponse>('/items/', params);
  }

  // 商品情報取得
  public getItem(id: number): Promise<ItemGetResponse> {
    const url = this.host + '/items/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<ItemGetResponse>('/items/' + id + '/', params);
  }

  // 商品情報登録
  public async addItem(data: ItemPostRequest): Promise<ItemPostResponse> {
    Utils.log(this.host + '/items/');
    return this.postWithToken<ItemPostRequest, ItemPostResponse>('/items/', data);
  }

  // 商品情報一括登録
  public async addItems(data: ItemPutRequest[]): Promise<any> {
    Utils.log(this.host + '/items/');
    return this.putWithToken<ItemPutRequest[], any>('/items/bulk_upsert/', data);
  }

  // 商品情報更新
  public async updateItem(id: number, data: ItemPutRequest): Promise<ItemPutResponse> {
    Utils.log(this.host + '/items/' + id + '/');
    return this.patchWithToken<ItemPutRequest, ItemPutResponse>('/items/' + id + '/', data);
  }

  // 商品情報削除
  public async deleteItem(id: number): Promise<ItemDeleteResponse> {
    Utils.log(this.host + '/items/' + id + '/');
    return this.deleteWithToken<ItemDeleteResponse>('/items/' + id + '/');
  }

  // 商品画像登録
  public async addItemImage(base_id: number, data: ItemImagePostRequest[]): Promise<any> {
    Utils.log(this.host + '/items/base/' + base_id + '/');
    return this.postWithToken<ItemImagePostRequest[], any>('/items/base/' + base_id + '/', data);
  }

  // 会社別訃報文サンプル一覧取得
  public getCompanyFuhoSampleList(request: CompanyFuhoSampleGetRequest): Promise<CompanyFuhoSampleGetResponse[]> {
    Utils.log(this.host + '/fuho_samples/' + '?company=' + request.company_id);
    let params = new HttpParams();
    params = params.set('company', String(request.company_id));
    return this.getListWithToken<CompanyFuhoSampleGetResponse>('/fuho_samples/', params);
  }

  // 会社別訃報文サンプル情報登録
  public async addCompanyFuhoSample(data: CompanyFuhoSamplePostRequest): Promise<CompanyFuhoSamplePostResponse> {
    Utils.log(this.host + '/fuho_samples/');
    return this.postWithToken<CompanyFuhoSamplePostRequest, CompanyFuhoSamplePostResponse>('/fuho_samples/', data);
  }

  // 会社別訃報文サンプル情報更新
  public async updateCompanyFuhoSample(id: number, data: CompanyFuhoSamplePutRequest): Promise<CompanyFuhoSamplePutResponse> {
    Utils.log(this.host + '/fuho_samples/' + id + '/');
    return this.patchWithToken<CompanyFuhoSamplePutRequest, CompanyFuhoSamplePutResponse>('/fuho_samples/' + id + '/', data);
  }

  // 会社別訃報文サンプル情報削除
  public async deleteCompanyFuhoSample(id: number): Promise<CompanyFuhoSampleDeleteResponse> {
    Utils.log(this.host + '/fuho_samples/' + id + '/');
    return this.deleteWithToken<CompanyFuhoSampleDeleteResponse>('/fuho_samples/' + id + '/');
  }

  // 会社別弔文台紙一覧取得
  public getCompanyChobunDaishiList(request: CompanyChobunDiashiGetRequest): Promise<CompanyChobunDiashiGetResponse[]> {
    Utils.log(this.host + '/chobun_daishi/' + '?company=' + request.company_id);
    let params = new HttpParams();
    params = params.set('company', String(request.company_id));
    return this.getList<CompanyChobunDiashiGetResponse>('/chobun_daishi/', params);
  }

  // 会社別弔文台紙登録
  public async addCompanyChobunDaishi(base_id, data: CompanyChobunDiashiPostRequest): Promise<any> {
    Utils.log(this.host + '/bases/' + base_id + '/');
    return this.patchWithToken<CompanyChobunDiashiPostRequest, any>('/bases/' + base_id + '/', data);
  }

  // 会社別サービス登録
  public async addCompanyService(base_id, data: CompanyServicePostRequest): Promise<any> {
    Utils.log(this.host + '/bases/' + base_id + '/');
    return this.patchWithToken<CompanyServicePostRequest, any>('/bases/' + base_id + '/', data);
  }

  // 会社別葬家専用ページメニュー登録
  public async addCompanySokeMenu(base_id, data: CompanySokeMenuPostRequest): Promise<any> {
    Utils.log(this.host + '/bases/' + base_id + '/');
    return this.patchWithToken<CompanySokeMenuPostRequest, any>('/bases/' + base_id + '/', data);
  }

  // サービス申込期限一覧取得
  public getServiceReceptionTermList(request: ServiceReceptionTermGetRequest): Promise<ServiceReceptionTermGetResponse[]> {
    Utils.log(this.host + '/service_reception_terms/' + '?department=' + request.department_id);
    let params = new HttpParams();
    params = params.set('department', String(request.department_id));
    return this.getListWithToken<ServiceReceptionTermGetResponse>('/service_reception_terms/', params);
  }

  // サービス申込期限登録
  public async addServiceReceptionTerm(base_id, data: ServiceReceptionTermPostRequest): Promise<any> {
    Utils.log(this.host + '/bases/' + base_id + '/');
    return this.patchWithToken<ServiceReceptionTermPostRequest, any>('/bases/' + base_id + '/', data);
  }

  // スタッフ一覧取得
  public getStaffList(base_id): Promise<StaffGetResponse[]> {
    Utils.log(this.host + '/staffs/lower/' + base_id + '/');
    const params = new HttpParams();
    return this.getListWithToken<StaffGetResponse>('/staffs/lower/' + base_id + '/', params);
  }

  // スタッフ情報登録
  public async addStaff(data: StaffPostRequest): Promise<StaffPostResponse> {
    Utils.log(this.host + '/staffs/');
    return this.postWithToken<StaffPostRequest, StaffPostResponse>('/staffs/', data);
  }

  // スタッフ情報更新
  public async updateStaff(id: number, data: StaffPutRequest): Promise<StaffPutResponse> {
    Utils.log(this.host + '/staffs/' + id + '/');
    return this.patchWithToken<StaffPutRequest, StaffPutResponse>('/staffs/' + id + '/', data);
  }

  // スタッフ情報削除
  public async deleteStaff(id: number): Promise<StaffDeleteResponse> {
    Utils.log(this.host + '/staffs/' + id + '/');
    return this.deleteWithToken<StaffDeleteResponse>('/staffs/' + id + '/');
  }

  // 日程項目一覧取得
  public getScheduleList(): Promise<ScheduleResponse[]> {
    Utils.log(this.host + '/masters/schedules/');
    const params = new HttpParams();
    return this.getListWithToken<ScheduleResponse>('/masters/schedules/', params);
  }

  // 間柄一覧取得
  public getRelationshipList(): Promise<RelationshipResponse[]> {
    Utils.log(this.host + '/masters/relationship/');
    const params = new HttpParams();
    return this.getList<RelationshipResponse>('/masters/relationship/', params);
  }

  // 返礼品パラメータ取得
  public getHenreihinParameter(): Promise<HenreihinParameterResponse> {
    Utils.log(this.host + '/masters/henrei_params/');
    const params = new HttpParams();
    return this.get<HenreihinParameterResponse>('/masters/henrei_params/', params);
  }

  // 香典パラメータ取得
  public getKodenParameter(): Promise<KodenParameterResponse> {
    Utils.log(this.host + '/masters/koden_params/');
    const params = new HttpParams();
    return this.get<KodenParameterResponse>('/masters/koden_params/', params);
  }

  // 施行形式一覧取得
  public getSekoStyleList(): Promise<SekoStyleGetResponse[]> {
    Utils.log(this.host + '/masters/seko_styles/');
    const params = new HttpParams();
    return this.getListWithToken<SekoStyleGetResponse>('/masters/seko_styles/', params);
  }

  // 法要形式一覧取得
  public getHoyoStyleList(): Promise<HoyoStyleGetResponse[]> {
    Utils.log(this.host + '/masters/hoyo_style/');
    const params = new HttpParams();
    return this.getListWithToken<HoyoStyleGetResponse>('/masters/hoyo_style/', params);
  }

  // 法要初期値一覧取得
  public getHoyoDefaultList(): Promise<HoyoDefaultGetResponse[]> {
    Utils.log(this.host + '/masters/hoyo_default/');
    const params = new HttpParams();
    return this.getListWithToken<HoyoDefaultGetResponse>('/masters/hoyo_default/', params);
  }

  // 法要サンプルマスタ一覧取得
  public getHoyoSampleMasterList(): Promise<HoyoSampleMasterGetResponse[]> {
    Utils.log(this.host + '/masters/hoyo_sample/');
    const params = new HttpParams();
    return this.getListWithToken<HoyoSampleMasterGetResponse>('/masters/hoyo_sample/', params);
  }
  // 訃報文一覧取得
  public getFuhoSampleList(): Promise<FuhoSampleGetResponse[]> {
    Utils.log(this.host + '/masters/fuho_sample/');
    const params = new HttpParams();
    return this.getListWithToken<FuhoSampleGetResponse>('/masters/fuho_sample/', params);
  }
  // 弔文台紙一覧取得
  public getChobunDiahiList(): Promise<ChobunDashiGetResponse[]> {
    Utils.log(this.host + '/masters/chobun_daishi/');
    const params = new HttpParams();
    return this.getListWithToken<ChobunDashiGetResponse>('/masters/chobun_daishi/', params);
  }
  // 弔文サンプル一覧取得
  public getChobunSampleList(): Promise<ChobunSampleGetResponse[]> {
    Utils.log(this.host + '/masters/chobun_sample/');
    const params = new HttpParams();
    return this.getList<ChobunSampleGetResponse>('/masters/chobun_sample/', params);
  }
  // サービス一覧取得
  public getServiceList(): Promise<ServiceGetResponse[]> {
    Utils.log(this.host + '/masters/services/');
    const params = new HttpParams();
    return this.getListWithToken<ServiceGetResponse>('/masters/services/', params);
  }
  // 税率一覧取得
  public getTaxList(): Promise<TaxGetResponse[]> {
    Utils.log(this.host + '/masters/taxes/');
    const params = new HttpParams();
    return this.getListWithToken<TaxGetResponse>('/masters/taxes/', params);
  }
  // 和暦一覧取得
  public getWarekiList(): Promise<WarekiGetResponse[]> {
    Utils.log(this.host + '/masters/wareki/');
    const params = new HttpParams();
    return this.getList<WarekiGetResponse>('/masters/wareki/', params);
  }
  // 住所取得
  public async getAddressList(request: AddressGetRequest): Promise<AddressGetResponse[]> {
    // await this.refreshToken();
    Utils.log(this.host + '/masters/zipcode/');
    let params = new HttpParams();
    params = params.set('search', request.zip_code);
    return this.getList<AddressGetResponse>('/masters/zipcode/', params);
    // return this.getListWithToken<AddressGetResponse>('/masters/zipcode/', params);
  }
  // 葬家専用ページメニュー一覧取得
  public getSokeMenuList(): Promise<SokeMenuGetResponse[]> {
    Utils.log(this.host + '/masters/soke_menu/');
    const params = new HttpParams();
    return this.getListWithToken<SokeMenuGetResponse>('/masters/soke_menu/', params);
  }
  // 施行情報取得
  public getSekoByid(id: number): Promise<SekoGetResponse> {
    Utils.log(this.host + '/seko/' + id + '/');
    const params = new HttpParams();
    return this.get<SekoGetResponse>('/seko/' + id + '/', params);
  }

  // 施行情報取得
  public async getSekoList(request: SekoGetRequest): Promise<SekoGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/seko/' + '?seko_company=' + request.seko_company;
    let params = new HttpParams();
    params = params.set('seko_company', String(request.seko_company));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.seko_style) {
      params = params.append('seko_style', String(request.seko_style));
      url += '&seko_style=' + request.seko_style;
    }
    if (request.hoyo_style) {
      params = params.append('hoyo_style', String(request.hoyo_style));
      url += '&hoyo_style=' + request.hoyo_style;
    }
    if (request.hall) {
      params = params.append('hall', String(request.hall));
      url += '&hall=' + request.hall;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.soke_kana) {
      params = params.append('soke_kana', request.soke_kana);
      url += '&soke_kana=' + request.soke_kana;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.kojin_kana) {
      params = params.append('kojin_kana', request.kojin_kana);
      url += '&kojin_kana=' + request.kojin_kana;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (request.moshu_kana) {
      params = params.append('moshu_kana', request.moshu_kana);
      url += '&moshu_kana=' + request.moshu_kana;
    }
    if (request.order_staff) {
      params = params.append('order_staff', String(request.order_staff));
      url += '&order_staff=' + request.order_staff;
    }
    if (request.seko_staff) {
      params = params.append('seko_staff', String(request.seko_staff));
      url += '&seko_staff=' + request.seko_staff;
    }
    if (request.af_staff) {
      params = params.append('af_staff', String(request.af_staff));
      url += '&af_staff=' + request.af_staff;
    }
    if (request.seko_date_after) {
      params = params.append('seko_date_after', request.seko_date_after);
      url += '&seko_date_after=' + request.seko_date_after;
    }
    if (request.seko_date_before) {
      params = params.append('seko_date_before', request.seko_date_before);
      url += '&seko_date_before=' + request.seko_date_before;
    }
    if (request.death_date_after) {
      params = params.append('death_date_after', request.death_date_after);
      url += '&death_date_after=' + request.death_date_after;
    }
    if (request.death_date_before) {
      params = params.append('death_date_before', request.death_date_before);
      url += '&death_date_before=' + request.death_date_before;
    }
    Utils.log(url);
    return this.getListWithToken<SekoGetResponse>('/seko/', params);
  }

  // 施行情報登録
  public async addSeko(seko: SekoPostRequest): Promise<SekoPostResponse> {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/');
    return this.postWithToken<SekoPostRequest, SekoPostResponse>('/seko/', seko);
  }

  // 施行情報更新
  public async updateSeko(id: number, seko: SekoPutRequest): Promise<SekoPutResponse> {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + id + '/');
    return this.patchWithToken<SekoPutRequest, SekoPutResponse>('/seko/' + id + '/', seko);
  }

  // 施行情報削除
  public async deleteSeko(id: number): Promise<SekoDeleteResponse> {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + id + '/');
    return this.deleteWithToken<SekoDeleteResponse>('/seko/' + id + '/');
  }

  // 施行情報承認
  public approveSeko(id: number): Promise<SekoGetResponse> {
    Utils.log(this.host + '/seko/' + id + '/approve/');
    const params = new HttpParams();
    return this.get<SekoGetResponse>('/seko/' + id + '/approve/', params);
  }

  // 葬家専用ページ承認
  public approveSokeSite(id: number): Promise<SekoGetResponse> {
    Utils.log(this.host + '/seko/soke/' + id + '/approve/');
    const params = new HttpParams();
    return this.get<SekoGetResponse>('/seko/soke/' + id + '/approve/', params);
  }

  // 葬家ログイン認証
  public sokeLogin(login: SokeLoginPostRequest): Promise<SokeLoginPostResponse> {
    Utils.log(this.host + '/seko/login/');
    return this.post<SokeLoginPostRequest, SokeLoginPostResponse>('/seko/login/', login);
  }


  // 喪主情報取得
  public async getMoshuList(request: MoshuGetRequest): Promise<MoshuGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/seko/moshu/' + '?company=' + request.company;
    let params = new HttpParams();
    params = params.set('company', String(request.company));
    if (request.prefecture) {
      params = params.append('prefecture', request.prefecture);
      url += '&prefecture=' + request.prefecture;
    }
    if (request.address_1) {
      params = params.append('address_1', request.address_1);
      url += '&address_1=' + request.address_1;
    }
    if (request.af_type.length) {
      params = params.append('af_type', request.af_type.join(','));
      url += '&af_type=' + request.af_type.join(',');
    }
    Utils.log(url);
    return this.getListWithToken<MoshuGetResponse>('/seko/moshu/', params);
  }

  // 喪主情報更新
  public async updateMoshu(id: number, moshu: MoshuPutRequest): Promise<MoshuPutResponse> {
    Utils.log(this.host + '/seko/moshu/' + id + '/');
    return this.patchWithToken<MoshuPutRequest, MoshuPutResponse>('/seko/moshu/' + id + '/', moshu);
  }

  // 芳名帳情報取得
  public getHomeicho(id: number): Promise<HomeichoGetResponse> {
    Utils.log(this.host + '/seko/' + id + '/homeicho/');
    const params = new HttpParams();
    return this.getWithToken<HomeichoGetResponse>('/seko/' + id + '/homeicho/', params);
  }

  // アルバム情報登録
  public addAlbum(seko_id: number, album: AlbumPostRequest): Promise<AlbumPostResponse> {
    Utils.log(this.host + '/seko/' + seko_id + '/albums/');
    return this.postWithToken<AlbumPostRequest, AlbumPostResponse>('/seko/' + seko_id + '/albums/', album);
  }

  // アルバム情報更新
  public updateAlbum(seko_id: number, id: number, album: AlbumPutRequest): Promise<AlbumPutResponse> {
    Utils.log(this.host + '/seko/' + seko_id + '/albums/' + id + '/');
    return this.patchWithToken<AlbumPutRequest, AlbumPutResponse>('/seko/' + seko_id + '/albums/' + id + '/', album);
  }

  // アルバム情報削除
  public deleteAlbum(seko_id: number, id: number): Promise<AlbumDeleteResponse> {
    Utils.log(this.host + '/seko/' + seko_id + '/albums/' + id + '/');
    return this.deleteWithToken<AlbumDeleteResponse>('/seko/' + seko_id + '/albums/' + id + '/');
  }

  // 故人情報更新
  public updateKojin(seko_id: number, id: number, kojin: KojinPutRequest): Promise<KojinPutResponse> {
    Utils.log(this.host + '/seko/' + seko_id + '/kojin/' + id + '/');
    return this.patchWithToken<KojinPutRequest, KojinPutResponse>('/seko/' + seko_id + '/kojin/' + id + '/', kojin);
  }

  // 法要施行情報取得
  public async getHoyoList(request: HoyoGetRequest): Promise<HoyoGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/hoyo/seko/' + '?seko_company=' + request.seko_company;
    let params = new HttpParams();
    params = params.set('seko_company', String(request.seko_company));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.hoyo) {
      params = params.append('hoyo', String(request.hoyo));
      url += '&hoyo=' + request.hoyo;
    }
    if (request.hoyo_planned_date_after) {
      params = params.append('hoyo_planned_date_after', request.hoyo_planned_date_after);
      url += '&hoyo_planned_date_after=' + request.hoyo_planned_date_after;
    }
    if (request.hoyo_planned_date_before) {
      params = params.append('hoyo_planned_date_before', request.hoyo_planned_date_before);
      url += '&hoyo_planned_date_before=' + request.hoyo_planned_date_before;
    }
    Utils.log(url);
    return this.getListWithToken<HoyoGetResponse>('/hoyo/seko/', params);
  }

  // 法要施行情報取得
  public getHoyoByid(id: number): Promise<HoyoGetResponse> {
    Utils.log(this.host + '/hoyo/seko/' + id + '/');
    const params = new HttpParams();
    return this.get<HoyoGetResponse>('/hoyo/seko/' + id + '/', params);
  }

  // 法要施行情報登録
  public addHoyo(hoyo: HoyoPostRequest): Promise<HoyoPostResponse> {
    Utils.log(this.host + '/hoyo/seko/');
    return this.postWithToken<HoyoPostRequest, HoyoPostResponse>('/hoyo/seko/', hoyo);
  }

  // 法要施行情報更新
  public updateHoyo(id: number, hoyo: HoyoPutRequest): Promise<HoyoPutResponse> {
    Utils.log(this.host + '/hoyo/seko/' + id + '/');
    return this.patchWithToken<HoyoPutRequest, HoyoPutResponse>('/hoyo/seko/' + id + '/', hoyo);
  }

  // 法要施行情報削除
  public deleteHoyo(id: number): Promise<HoyoDeleteResponse> {
    Utils.log(this.host + '/hoyo/seko/' + id + '/');
    return this.deleteWithToken<HoyoDeleteResponse>('/hoyo/seko/' + id + '/');
  }

  // 訃報URL送信
  public async sendFuhoUrl(seko_id: number, fuhoUrl: FuhoMailPostRequest): Promise<FuhoMailPostResponse> {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + seko_id + '/moshu_mail/');
    return this.postWithToken<FuhoMailPostRequest, FuhoMailPostResponse>('/seko/' + seko_id + '/moshu_mail/', fuhoUrl);
  }

  // 添付ファイル通知送信
  public async noticeAttachedFile(seko_id: number, data: NoticeAttachedFilePostRequest): Promise<NoticeAttachedFilePostResponse> {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + seko_id + '/notice_attached_file/');
    return this.postWithToken<NoticeAttachedFilePostRequest, NoticeAttachedFilePostResponse>(
      '/seko/' + seko_id + '/notice_attached_file/', data
      );
  }

  // 訃報PDFダウンロード
  public async downloadFuhoPdf(seko_id: number) {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + seko_id + '/pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/pdf/');
  }
  // 訃報QRコードダウンロード
  public async downloadRQCode(seko_id: number) {
    // await this.refreshToken();
    Utils.log(this.host + '/seko/' + seko_id + '/qrcode/');

    return this.downloadWithToken('/seko/' + seko_id + '/qrcode/');
  }

  // 芳名帳（弔文）PDFダウンロード
  public async downloadHomeiChobunPdf(seko_id: number) {
    Utils.log(this.host + '/seko/' + seko_id + '/homeicho/chobun_pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/homeicho/chobun_pdf/');
  }

  // 芳名帳（供花・供物）PDFダウンロード
  public async downloadHomeiKumotsuPdf(seko_id: number) {
    Utils.log(this.host + '/seko/' + seko_id + '/homeicho/kumotsu_pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/homeicho/kumotsu_pdf/');
  }

  // 芳名帳（香典）PDFダウンロード
  public async downloadHomeiKodenPdf(seko_id: number) {
    Utils.log(this.host + '/seko/' + seko_id + '/homeicho/koden_pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/homeicho/koden_pdf/');
  }

  // 芳名帳（追悼メッセージ）PDFダウンロード
  public async downloadHomeiMsgPdf(seko_id: number) {
    Utils.log(this.host + '/seko/' + seko_id + '/homeicho/msg_pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/homeicho/msg_pdf/');
  }

  // 追悼メッセージPDFダウンロード
  public async downloadMsgPdf(seko_id: number) {
    Utils.log(this.host + '/seko/' + seko_id + '/msg_pdf/');

    return this.downloadWithToken('/seko/' + seko_id + '/msg_pdf/');
  }

  // 法要案内状PDFダウンロード
  public async downloadHoyoPdf(hoyo_id: number) {
    // await this.refreshToken();
    Utils.log(this.host + '/hoyo/seko/' + hoyo_id + '/pdf/');

    return this.downloadWithToken('/hoyo/seko/' + hoyo_id + '/pdf/');
  }

  // 申込み一覧取得
  public getEntryList(request: EntryGetResquest): Promise<EntryGetResponse[]> {
    let url = this.host + '/orders/';
    let params = new HttpParams();
    let sign = '?';
    if (request.seko_company) {
      params = params.append('seko_company', String(request.seko_company));
      url += sign + 'seko_company=' + request.seko_company;
      sign = '&';
    }
    if (request.entry_ts_from) {
      params = params.append('entry_ts_from', request.entry_ts_from);
      url += sign + 'entry_ts_from=' + request.entry_ts_from;
      sign = '&';
    }
    if (request.entry_ts_to) {
      params = params.append('entry_ts_to', request.entry_ts_to);
      url += sign + 'entry_ts_to=' + request.entry_ts_to;
    }
    if (request.kumotsu_henrei_from) {
      params = params.append('kumotsu_henrei_from', request.kumotsu_henrei_from);
      url += sign + 'kumotsu_henrei_from=' + request.kumotsu_henrei_from;
      sign = '&';
    }
    if (request.kumotsu_henrei_to) {
      params = params.append('kumotsu_henrei_to', request.kumotsu_henrei_to);
      url += sign + 'kumotsu_henrei_to=' + request.kumotsu_henrei_to;
    }
    if (request.koden_henrei_from) {
      params = params.append('koden_henrei_from', request.koden_henrei_from);
      url += sign + 'koden_henrei_from=' + request.koden_henrei_from;
      sign = '&';
    }
    if (request.koden_henrei_to) {
      params = params.append('koden_henrei_to', request.koden_henrei_to);
      url += sign + 'koden_henrei_to=' + request.koden_henrei_to;
    }
    Utils.log(url);
    return this.getListWithToken<EntryGetResponse>('/orders/', params);
  }

  // 申込み情報取得
  public getEntry(id: number): Promise<EntryGetResponse> {
    Utils.log(this.host + '/orders/' + id + '/');
    const params = new HttpParams();
    return this.getWithToken<EntryGetResponse>('/orders/' + id + '/', params);
  }

  // 申込み情報登録
  public async addEntry(entry: EntryPostRequest): Promise<EntryPostResponse> {
    Utils.log(this.host + '/orders/');
    return this.post<EntryPostRequest, EntryPostResponse>('/orders/', entry);
  }

  // 申込みキャンセル
  public async cancelEntry(id: number) {
    Utils.log(this.host + '/orders/' + id + '/cancel/');
    return this.postWithToken<any, any>('/orders/' + id + '/cancel/', null);
  }


  // 領収証ダウンロード
  public async downloadReceipt(entry_id: number, atena: string) {
    let params = new HttpParams();
    params = params.set('atena', atena);
    Utils.log(this.host + '/orders/' + entry_id + '/receipt/?atena=' + atena);
    return this.download('/orders/' + entry_id + '/receipt/', params);
  }

  // 弔文PDFダウンロード
  public async downloadChobunPdf(detail_id: number) {
    Utils.log(this.host + '/orders/detail/' + detail_id + '/chobun_pdf/');
    return this.downloadWithToken('/orders/detail/' + detail_id + '/chobun_pdf/');
  }

  // 供花・供物発注PDFダウンロード
  public async downloadKumotsuPdf(detail_id: number) {
    Utils.log(this.host + '/orders/kumotsu/' + detail_id + '/pdf/');
    return this.downloadWithToken('/orders/kumotsu/' + detail_id + '/pdf/');
  }

  // 供花・供物発注PDFファクス
  public async faxKumotsuPdf(detail_id: number) {
    Utils.log(this.host + '/orders/kumotsu/' + detail_id + '/fax/');
    return this.postWithToken<any, any>('/orders/kumotsu/' + detail_id + '/fax/', null);
  }

  // 弔文申込一覧取得
  public async getChobunList(request: ChobunGetRequest): Promise<ChobunGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/orders/chobun/' + '?company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.entry_id) {
      params = params.append('entry_id', String(request.entry_id));
      url += '&entry_id=' + request.entry_id;
    }
    if (request.okurinushi_name) {
      params = params.append('okurinushi_name', String(request.okurinushi_name));
      url += '&okurinushi_name=' + request.okurinushi_name;
    }
    if (request.entry_name) {
      params = params.append('entry_name', request.entry_name);
      url += '&entry_name=' + request.entry_name;
    }
    if (request.entry_tel) {
      params = params.append('entry_tel', request.entry_tel);
      url += '&entry_tel=' + request.entry_tel;
    }
    if (request.renmei) {
      params = params.append('renmei', request.renmei);
      url += '&renmei=' + request.renmei;
    }
    if (request.okurinushi_company) {
      params = params.append('okurinushi_company', request.okurinushi_company);
      url += '&okurinushi_company=' + request.okurinushi_company;
    }
    if (request.okurinushi_title) {
      params = params.append('okurinushi_title', String(request.okurinushi_title));
      url += '&okurinushi_title=' + request.okurinushi_title;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (!isNullOrUndefined(request.printed_flg)) {
      params = params.append('printed_flg', String(!!request.printed_flg));
      url += '&printed_flg=' + !!request.printed_flg;

    }
    if (!isNullOrUndefined(request.is_cancel)) {
      params = params.append('is_cancel', String(!!request.is_cancel));
      url += '&is_cancel=' + !!request.is_cancel;
    }
    Utils.log(url);
    return this.getListWithToken<ChobunGetResponse>('/orders/chobun/', params);
  }

  // 弔文申込情報更新
  public updateChobun(id: number, chobun: ChobunPutRequest): Promise<ChobunPutResponse> {
    Utils.log(this.host + '/orders/chobun/' + id + '/');
    return this.patchWithToken<ChobunPutRequest, ChobunPutResponse>('/orders/chobun/' + id + '/', chobun);
  }

  // 供花・供物申込一覧取得
  public async getKumotsuList(request: KumotsuGetRequest): Promise<KumotsuGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/orders/kumotsu/' + '?company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.entry_id) {
      params = params.append('entry_id', String(request.entry_id));
      url += '&entry_id=' + request.entry_id;
    }
    if (request.okurinushi_name) {
      params = params.append('okurinushi_name', String(request.okurinushi_name));
      url += '&okurinushi_name=' + request.okurinushi_name;
    }
    if (request.entry_name) {
      params = params.append('entry_name', request.entry_name);
      url += '&entry_name=' + request.entry_name;
    }
    if (request.entry_tel) {
      params = params.append('entry_tel', request.entry_tel);
      url += '&entry_tel=' + request.entry_tel;
    }
    if (request.renmei) {
      params = params.append('renmei', request.renmei);
      url += '&renmei=' + request.renmei;
    }
    if (request.okurinushi_company) {
      params = params.append('okurinushi_company', request.okurinushi_company);
      url += '&okurinushi_company=' + request.okurinushi_company;
    }
    if (request.okurinushi_title) {
      params = params.append('okurinushi_title', String(request.okurinushi_title));
      url += '&okurinushi_title=' + request.okurinushi_title;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (!isNullOrUndefined(request.is_cancel)) {
      params = params.append('is_cancel', String(!!request.is_cancel));
      url += '&is_cancel=' + !!request.is_cancel;
    }
    if (request.supplier_id) {
      params = params.append('supplier_id', String(request.supplier_id));
      url += '&supplier_id=' + request.supplier_id;
    }
    if (request.order_status && request.order_status.length) {
      params = params.append('order_status', request.order_status.join(','));
      url += '&order_status=' + request.order_status.join(',');
    }
    Utils.log(url);
    return this.getListWithToken<KumotsuGetResponse>('/orders/kumotsu/', params);
  }

  // 供花・供物申込情報更新
  public updateKumotsu(id: number, kumotsu: KumotsuPutRequest): Promise<KumotsuPutResponse> {
    Utils.log(this.host + '/orders/kumotsu/' + id + '/');
    return this.patchWithToken<KumotsuPutRequest, KumotsuPutResponse>('/orders/kumotsu/' + id + '/', kumotsu);
  }

  // 供花・供物ステータス更新
  public updateKumotsuStatus(status: KumotsuOrderStatusPostRequest): Promise<KumotsuOrderStatusPostResponse> {
    Utils.log(this.host + '/orders/kumotsu/order_status/');
    return this.postWithToken<KumotsuOrderStatusPostRequest, KumotsuOrderStatusPostResponse>('/orders/kumotsu/order_status/', status);
  }

  // 香典申込一覧取得
  public async getKodenList(request: KodenGetRequest): Promise<KodenGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/orders/koden/' + '?company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.entry_id) {
      params = params.append('entry_id', String(request.entry_id));
      url += '&entry_id=' + request.entry_id;
    }
    if (request.entry_name) {
      params = params.append('entry_name', request.entry_name);
      url += '&entry_name=' + request.entry_name;
    }
    if (request.entry_tel) {
      params = params.append('entry_tel', request.entry_tel);
      url += '&entry_tel=' + request.entry_tel;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (!isNullOrUndefined(request.is_cancel)) {
      params = params.append('is_cancel', String(!!request.is_cancel));
      url += '&is_cancel=' + !!request.is_cancel;
    }
    Utils.log(url);
    return this.getListWithToken<KodenGetResponse>('/orders/koden/', params);
  }

  // 追悼メッセージ申込一覧取得
  public async getMsgList(request: MessageGetRequest): Promise<MessageGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/orders/msg/' + '?company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.entry_id) {
      params = params.append('entry_id', String(request.entry_id));
      url += '&entry_id=' + request.entry_id;
    }
    if (request.entry_name) {
      params = params.append('entry_name', request.entry_name);
      url += '&entry_name=' + request.entry_name;
    }
    if (request.entry_tel) {
      params = params.append('entry_tel', request.entry_tel);
      url += '&entry_tel=' + request.entry_tel;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (!isNullOrUndefined(request.is_cancel)) {
      params = params.append('is_cancel', String(!!request.is_cancel));
      url += '&is_cancel=' + !!request.is_cancel;
    }
    if (request.release_status && request.release_status.length) {
      params = params.append('release_status', request.release_status.join(','));
      url += '&release_status=' + request.release_status.join(',');
    }
    Utils.log(url);
    return this.getListWithToken<MessageGetResponse>('/orders/msg/', params);
  }

  // 追悼メッセージ情報更新
  public updateMessage(id: number, msg: MessagePutRequest): Promise<MessagePutResponse> {
    Utils.log(this.host + '/orders/msg/' + id + '/');
    return this.patchWithToken<MessagePutRequest, MessagePutResponse>('/orders/msg/' + id + '/', msg);
  }

  // 返礼品申込一覧取得
  public async getHenreihinList(request: HenreihinGetRequest): Promise<HenreihinGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/henrei/list/' + '?company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.seko_id) {
      params = params.append('seko_id', String(request.seko_id));
      url += '&seko_id=' + request.seko_id;
    }
    if (request.supplier_id) {
      params = params.append('supplier_id', String(request.supplier_id));
      url += '&supplier_id=' + request.supplier_id;
    }
    if (request.hall_id) {
      params = params.append('hall_id', String(request.hall_id));
      url += '&hall_id=' + request.hall_id;
    }
    if (request.seko_date) {
      params = params.append('seko_date', request.seko_date);
      url += '&seko_date=' + request.seko_date;
    }
    if (request.soke_name) {
      params = params.append('soke_name', request.soke_name);
      url += '&soke_name=' + request.soke_name;
    }
    if (request.kojin_name) {
      params = params.append('kojin_name', request.kojin_name);
      url += '&kojin_name=' + request.kojin_name;
    }
    if (request.moshu_name) {
      params = params.append('moshu_name', request.moshu_name);
      url += '&moshu_name=' + request.moshu_name;
    }
    if (!isNullOrUndefined(request.is_cancel)) {
      params = params.append('is_cancel', String(!!request.is_cancel));
      url += '&is_cancel=' + !!request.is_cancel;
    }
    if (request.order_status && request.order_status.length) {
      params = params.append('order_status', request.order_status.join(','));
      url += '&order_status=' + request.order_status.join(',');
    }
    Utils.log(url);
    return this.getListWithToken<HenreihinGetResponse>('/henrei/list/', params);
  }

  // 香典返礼品登録
  public addHenreihinKoden(koden: HenreihinPostRequest): Promise<HenreihinPostResponse> {
    Utils.log(this.host + '/henrei/koden/');
    return this.postWithToken<HenreihinPostRequest, HenreihinPostResponse>('/henrei/koden/', koden);
  }

  // 香典返礼品情報更新
  public updateHenreihinKoden(id: number, koden: HenreihinPutRequest): Promise<HenreihinPutResponse> {
    Utils.log(this.host + '/henrei/koden/' + id + '/');
    return this.patchWithToken<HenreihinPutRequest, HenreihinPutResponse>('/henrei/koden/' + id + '/', koden);
  }

  // 香典返礼品情報削除
  public deleteHenreihinKoden(id: number): Promise<any> {
    Utils.log(this.host + '/henrei/koden/' + id + '/');
    return this.deleteWithToken<any>('/henrei/koden/' + id + '/');
  }

  // 返礼品確定
  public confirmHenreihin(data: HenreiSelectStatusPostRequest): Promise<HenreiSelectStatusPostResponse> {
    Utils.log(this.host + '/henrei/place/');
    return this.postWithToken<HenreiSelectStatusPostRequest, HenreiSelectStatusPostResponse>('/henrei/place/', data);
  }

  // 供花・供物返礼品登録
  public addHenreihinKumotsu(koden: HenreihinPostRequest): Promise<HenreihinPostResponse> {
    Utils.log(this.host + '/henrei/kumotsu/');
    return this.postWithToken<HenreihinPostRequest, HenreihinPostResponse>('/henrei/kumotsu/', koden);
  }

  // 供花・供物返礼品情報更新
  public updateHenreihinKumotsu(id: number, kumotsu: HenreihinPutRequest): Promise<HenreihinPutResponse> {
    Utils.log(this.host + '/henrei/kumotsu/' + id + '/');
    return this.patchWithToken<HenreihinPutRequest, HenreihinPutResponse>('/henrei/kumotsu/' + id + '/', kumotsu);
  }

  // 供花・供物返礼品情報削除
  public deleteHenreihinKumotsu(id: number): Promise<any> {
    Utils.log(this.host + '/henrei/kumotsu/' + id + '/');
    return this.deleteWithToken<any>('/henrei/kumotsu/' + id + '/');
  }

  // 返礼品発注一覧取得
  public async getOrderHenreiList(request: OrderHenreiGetRequest): Promise<OrderHenreiGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/henrei/orders/' + '?seko_company_id=' + request.company_id;
    let params = new HttpParams();
    params = params.set('seko_company_id', String(request.company_id));
    if (request.seko_department) {
      params = params.append('seko_department', String(request.seko_department));
      url += '&seko_department=' + request.seko_department;
    }
    if (request.supplier_id) {
      params = params.append('supplier_id', String(request.supplier_id));
      url += '&supplier_id=' + request.supplier_id;
    }
    if (request.order_henrei_id) {
      params = params.append('order_henrei_id', String(request.order_henrei_id));
      url += '&order_henrei_id=' + request.order_henrei_id;
    }
    if (request.order_ts_from) {
      params = params.append('order_ts_from', request.order_ts_from);
      url += '&order_ts_from=' + request.order_ts_from;
    }
    if (request.order_ts_to) {
      params = params.append('order_ts_to', request.order_ts_to);
      url += '&order_ts_to=' + request.order_ts_to;
    }
    if (request.order_status && request.order_status.length) {
      params = params.append('order_status', request.order_status.join(','));
      url += '&order_status=' + request.order_status.join(',');
    }
    Utils.log(url);
    return this.getListWithToken<OrderHenreiGetResponse>('/henrei/orders/', params);
  }

  // 返礼品発注登録
  public addOrderHenrei(status: HenreihinOrderPostRequest): Promise<HenreihinOrderPostResponse> {
    Utils.log(this.host + '/henrei/add/');
    return this.postWithToken<HenreihinOrderPostRequest, HenreihinOrderPostResponse>('/henrei/add/', status);
  }

  // 返礼品発注更新
  public updateOrderHenrei(id: number, order_henrei: OrderHenreiPutRequest): Promise<OrderHenreiPutResponse> {
    Utils.log(this.host + '/henrei/orders/' + id + '/');
    return this.patchWithToken<OrderHenreiPutRequest, OrderHenreiPutResponse>('/henrei/orders/' + id + '/', order_henrei);
  }

  // 返礼品発注ステータス更新
  public updateOrderHenreiStatus(status: OrderHenreiOrderStatusPostRequest): Promise<OrderHenreiOrderStatusPostResponse> {
    Utils.log(this.host + '/henrei/orders/order_status/');
    return this.postWithToken<OrderHenreiOrderStatusPostRequest, OrderHenreiOrderStatusPostResponse>(
      '/henrei/orders/order_status/', status
    );
  }

  // 返礼品発注PDFダウンロード
  public async downloadOrderHenreiPdf(id: number) {
    Utils.log(this.host + '/henrei/orders/' + id + '/pdf/');
    return this.downloadWithToken('/henrei/orders/' + id + '/pdf/');
  }

  // 返礼品発注PDFファクス
  public async faxOrderHenreiPdf(id: number) {
    Utils.log(this.host + '/henrei/orders/' + id + '/fax/');
    return this.postWithToken<any, any>('/henrei/orders/' + id + '/fax/', null);
  }

  // 問い合わせメール送信
  public async sendInquiry(inquiry: InquiryPostRequest): Promise<InquiryPostResponse> {
    Utils.log(this.host + '/inquiry/');
    return this.post<InquiryPostRequest, InquiryPostResponse>('/inquiry/', inquiry);
  }

  // 月次集計
  public monthlyGather(invoice: InvoiceGatherPostRequest): Promise<InvoiceGatherPostResponse> {
    Utils.log(this.host + '/invoices/gather_sales/');
    return this.postWithToken<InvoiceGatherPostRequest, InvoiceGatherPostResponse>(
      '/invoices/gather_sales/', invoice
    );
  }

  // 売上一覧取得
  public async getSalesList(request: SalesGetRequest): Promise<SalesGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/invoices/sales/';
    let params = new HttpParams();
    let sign = '?';
    if (request.company) {
      params = params.append('company', String(request.company));
      url += sign + 'company=' + request.company;
      sign = '&';
    }
    if (request.sales_yymm) {
      params = params.append('sales_yymm', request.sales_yymm);
      url += sign + 'sales_yymm=' + request.sales_yymm;
    }
    Utils.log(url);
    return this.getListWithToken<SalesGetResponse>('/invoices/sales/', params);
  }

  // 月次確定
  public fixSales(id: number): Promise<any> {
    Utils.log(this.host + '/invoices/sales/' + id + '/fix/');
    return this.postWithToken<any, any>('/invoices/sales/' + id + '/fix/', null);
  }

  // 月次確定取消
  public cancelFixSales(id: number): Promise<any> {
    Utils.log(this.host + '/invoices/sales/' + id + '/fix/cancel/');
    return this.postWithToken<any, any>('/invoices/sales/' + id + '/fix/cancel/', null);
  }

  // 請求書PDFダウンロード
  public async downloadInvoicePdf(id: number) {
    Utils.log(this.host + '/invoices/' + id + '/pdf/');
    return this.downloadWithToken('/invoices/' + id + '/pdf/');
  }

  // AF項目グループ一覧取得(子供拠点含む)
  public async getAfGroupListAll(request: AfGroupGetRequest): Promise<AfGroupGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/after_follow/groups/all/';
    let params = new HttpParams();
    if (request.department) {
      params = params.append('department', String(request.department));
      url += '?department=' + request.department;
    }
    Utils.log(url);
    return this.getListWithToken<AfGroupGetResponse>('/after_follow/groups/all/', params);
  }

  // AF項目グループ一覧取得
  public async getAfGroupList(request: AfGroupGetRequest): Promise<AfGroupGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/after_follow/groups/';
    let params = new HttpParams();
    if (request.department) {
      params = params.append('department', String(request.department));
      url += '?department=' + request.department;
    }
    Utils.log(url);
    return this.getListWithToken<AfGroupGetResponse>('/after_follow/groups/', params);
  }

  // AF項目グループ取得
  public async getAfGroup(id: number): Promise<AfGroupGetResponse> {
    // await this.refreshToken();
    const url = this.host + '/after_follow/groups/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<AfGroupGetResponse>('/after_follow/groups/' + id + '/', params);
  }

  // AF項目グループ登録
  public addAfGroup(af_group: AfGroupPostRequest): Promise<AfGroupPostResponse> {
    Utils.log(this.host + '/after_follow/groups/');
    return this.postWithToken<AfGroupPostRequest, AfGroupPostResponse>('/after_follow/groups/', af_group);
  }

  // AF項目グループ更新
  public updateAfGroup(id: number, af_group: AfGroupPutRequest): Promise<AfGroupPutResponse> {
    Utils.log(this.host + '/after_follow/groups/' + id + '/');
    return this.patchWithToken<AfGroupPutRequest, AfGroupPutResponse>('/after_follow/groups/' + id + '/', af_group);
  }

  // AF項目グループ削除
  public deleteAfGroup(id: number): Promise<AfGroupDeleteResponse> {
    Utils.log(this.host + '/after_follow/groups/' + id + '/');
    return this.deleteWithToken<AfGroupDeleteResponse>('/after_follow/groups/' + id + '/');
  }

  // AF項目登録
  public addAf(af: AfPostRequest): Promise<AfPostResponse> {
    Utils.log(this.host + '/after_follow/');
    return this.postWithToken<AfPostRequest, AfPostResponse>('/after_follow/', af);
  }

  // AF項目更新
  public updateAf(id: number, af: AfPutRequest): Promise<AfPutResponse> {
    Utils.log(this.host + '/after_follow/' + id + '/');
    return this.patchWithToken<AfPutRequest, AfPutResponse>('/after_follow/' + id + '/', af);
  }

  // AF項目削除
  public deleteAf(id: number): Promise<AfDeleteResponse> {
    Utils.log(this.host + '/after_follow/' + id + '/');
    return this.deleteWithToken<AfDeleteResponse>('/after_follow/' + id + '/');
  }

  // AF連絡方法一覧取得
  public async getAfContactList(request: AfContactGetRequest): Promise<AfContactGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/after_follow/contact_ways/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<AfContactGetResponse>('/after_follow/contact_ways/', params);
  }

  // AF連絡方法取得
  public async getAfContact(id: number): Promise<AfContactGetResponse> {
    // await this.refreshToken();
    const url = this.host + '/after_follow/contact_ways/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<AfContactGetResponse>('/after_follow/contact_ways/' + id + '/', params);
  }

  // AF連絡方法登録
  public addAfContact(af_contact: AfContactPostRequest): Promise<AfContactPostResponse> {
    Utils.log(this.host + '/after_follow/contact_ways/');
    return this.postWithToken<AfContactPostRequest, AfContactPostResponse>('/after_follow/contact_ways/', af_contact);
  }

  // AF連絡方法更新
  public updateAfContact(id: number, af_contact: AfContactPutRequest): Promise<AfContactPutResponse> {
    Utils.log(this.host + '/after_follow/contact_ways/' + id + '/');
    return this.patchWithToken<AfContactPutRequest, AfContactPutResponse>('/after_follow/contact_ways/' + id + '/', af_contact);
  }

  // AF連絡方法削除
  public deleteAfContact(id: number): Promise<AfContactDeleteResponse> {
    Utils.log(this.host + '/after_follow/contact_ways/' + id + '/');
    return this.deleteWithToken<AfContactDeleteResponse>('/after_follow/contact_ways/' + id + '/');
  }

  // 法要マスタ一覧取得
  public async getHoyoMasterList(request: HoyoMasterGetRequest): Promise<HoyoMasterGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/hoyo/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<HoyoMasterGetResponse>('/hoyo/', params);
  }

  // 法要マスタ取得
  public async getHoyoMaster(id: number): Promise<HoyoMasterGetResponse> {
    // await this.refreshToken();
    const url = this.host + '/hoyo/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<HoyoMasterGetResponse>('/hoyo/' + id + '/', params);
  }

  // 法要マスタ登録
  public addHoyoMaster(hoyo: HoyoMasterPostRequest): Promise<HoyoMasterPostResponse> {
    Utils.log(this.host + '/hoyo/');
    return this.postWithToken<HoyoMasterPostRequest, HoyoMasterPostResponse>('/hoyo/', hoyo);
  }

  // 法要マスタ更新
  public updateHoyoMaster(id: number, hoyo: HoyoMasterPutRequest): Promise<HoyoMasterPutResponse> {
    Utils.log(this.host + '/hoyo/' + id + '/');
    return this.patchWithToken<HoyoMasterPutRequest, HoyoMasterPutResponse>('/hoyo/' + id + '/', hoyo);
  }

  // 法要マスタ削除
  public deleteHoyoMaster(id: number): Promise<HoyoMasterDeleteResponse> {
    Utils.log(this.host + '/hoyo/' + id + '/');
    return this.deleteWithToken<HoyoMasterDeleteResponse>('/hoyo/' + id + '/');
  }

  // 法要案内文一覧取得
  public async getHoyoSampleList(request: HoyoSampleGetRequest): Promise<HoyoSampleGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/hoyo/samples/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<HoyoSampleGetResponse>('/hoyo/samples/', params);
  }

  // 法要案内文取得
  public async getHoyoSample(id: number): Promise<HoyoSampleGetResponse> {
    // await this.refreshToken();
    const url = this.host + '/hoyo/samples/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<HoyoSampleGetResponse>('/hoyo/samples/' + id + '/', params);
  }

  // 法要案内文登録
  public addHoyoSample(hoyo: HoyoSamplePostRequest): Promise<HoyoSamplePostResponse> {
    Utils.log(this.host + '/hoyo/samples/');
    return this.postWithToken<HoyoSamplePostRequest, HoyoSamplePostResponse>('/hoyo/samples/', hoyo);
  }

  // 法要案内文更新
  public updateHoyoSample(id: number, hoyo: HoyoSamplePutRequest): Promise<HoyoSamplePutResponse> {
    Utils.log(this.host + '/hoyo/samples/' + id + '/');
    return this.patchWithToken<HoyoSamplePutRequest, HoyoSamplePutResponse>('/hoyo/samples/' + id + '/', hoyo);
  }

  // 法要案内文削除
  public deleteHoyoSample(id: number): Promise<HoyoSampleDeleteResponse> {
    Utils.log(this.host + '/hoyo/samples/' + id + '/');
    return this.deleteWithToken<HoyoSampleDeleteResponse>('/hoyo/samples/' + id + '/');
  }

  // 法要メールテンプレート一覧取得
  public async getHoyoMailTemplateList(request: HoyoMailTemplateGetRequest): Promise<HoyoMailTemplateGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/hoyo/mail_templates/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<HoyoMailTemplateGetResponse>('/hoyo/mail_templates/', params);
  }

  // 法要メールテンプレート取得
  public async getHoyoMailTemplate(id: number): Promise<HoyoMailTemplateGetResponse> {
    // await this.refreshToken();
    const url = this.host + '/hoyo/mail_templates/' + id + '/';
    const params = new HttpParams();
    Utils.log(url);
    return this.getWithToken<HoyoMailTemplateGetResponse>('/hoyo/mail_templates/' + id + '/', params);
  }

  // 法要メールテンプレート登録
  public addHoyoMailTemplate(hoyo: HoyoMailTemplatePostRequest): Promise<HoyoMailTemplatePostResponse> {
    Utils.log(this.host + '/hoyo/mail_templates/');
    return this.postWithToken<HoyoMailTemplatePostRequest, HoyoMailTemplatePostResponse>('/hoyo/mail_templates/', hoyo);
  }

  // 法要メールテンプレート更新
  public updateHoyoMailTemplate(id: number, hoyo: HoyoMailTemplatePutRequest): Promise<HoyoMailTemplatePutResponse> {
    Utils.log(this.host + '/hoyo/mail_templates/' + id + '/');
    return this.patchWithToken<HoyoMailTemplatePutRequest, HoyoMailTemplatePutResponse>('/hoyo/mail_templates/' + id + '/', hoyo);
  }

  // 法要メールテンプレート削除
  public deleteHoyoMailTemplate(id: number): Promise<HoyoMailTemplateDeleteResponse> {
    Utils.log(this.host + '/hoyo/mail_templates/' + id + '/');
    return this.deleteWithToken<HoyoMailTemplateDeleteResponse>('/hoyo/mail_templates/' + id + '/');
  }

  // 施行AF登録
  public addSekoAf(seko_af: SekoAfPostRequest): Promise<SekoAfPostResponse> {
    Utils.log(this.host + '/after_follow/sekoaf/');
    return this.postWithToken<SekoAfPostRequest, SekoAfPostResponse>('/after_follow/sekoaf/', seko_af);
  }

  // 施行AF更新
  public updateSekoAf(id: number, seko_af: SekoAfPutRequest): Promise<SekoAfPutResponse> {
    Utils.log(this.host + '/after_follow/sekoaf/' + id + '/');
    return this.patchWithToken<SekoAfPutRequest, SekoAfPutResponse>('/after_follow/sekoaf/' + id + '/', seko_af);
  }

  // 施行AF活動状況更新
  public updateAfWish(id: number, af_wish: AfWishForPost): Promise<AfWishForGet> {
    Utils.log(this.host + '/after_follow/wish/' + id + '/');
    return this.patchWithToken<AfWishForPost, AfWishForGet>('/after_follow/wish/' + id + '/', af_wish);
  }

  // 施行AF活動内容登録
  public addAfActivity(af_activity: AfActivityForPost): Promise<AfActivityForGet> {
    Utils.log(this.host + '/after_follow/activity/');
    return this.postWithToken<AfActivityForPost, AfActivityForGet>('/after_follow/activity/', af_activity);
  }

  // 施行AF活動内容更新
  public updateAfActivity(id: number, af_activity: AfActivityForPost): Promise<AfActivityForGet> {
    Utils.log(this.host + '/after_follow/activity/' + id + '/');
    return this.patchWithToken<AfActivityForPost, AfActivityForGet>('/after_follow/activity/' + id + '/', af_activity);
  }

  // 施行問い合わせ一覧取得
  public async getSekoInquiryList(seko_id: number): Promise<SekoInquiryGetResponse[]> {
    Utils.log(this.host + '/seko/' + seko_id + '/inquiries/');
    const params = new HttpParams();
    return this.getListWithToken<SekoInquiryGetResponse>('/seko/' + seko_id + '/inquiries/', params);
  }

  // 施行問い合わせ登録
  public addSekoInquiry(seko_id: number, inquiry: SekoInquiryPostRequest): Promise<SekoInquiryPostResponse> {
    Utils.log(this.host + '/seko/' + seko_id + '/inquiries/');
    return this.postWithToken<SekoInquiryPostRequest, SekoInquiryPostResponse>('/seko/' + seko_id + '/inquiries/', inquiry);
  }
  // 施行問い合わせ回答登録
  public addSekoAnswer(id: number, answer: SekoAnswerPostRequest): Promise<SekoAnswerPostResponse> {
    Utils.log(this.host + '/seko/inquiries/' + id + '/answers/');
    return this.postWithToken<SekoAnswerPostRequest, SekoAnswerPostResponse>('/seko/inquiries/' + id + '/answers/', answer);
  }

  // 法要メール一覧取得
  public async getHoyoMailList(request: HoyoMailGetRequest): Promise<HoyoMailGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/hoyo/mails/' + '?company=' + request.company;
    let params = new HttpParams();
    params = params.set('company', String(request.company));
    if (request.hoyo) {
      params = params.append('hoyo', String(request.hoyo));
      url += '&hoyo=' + request.hoyo;
    }
    if (request.send_date_after) {
      params = params.append('send_date_after', request.send_date_after);
      url += '&send_date_after=' + request.send_date_after;
    }
    if (request.send_date_before) {
      params = params.append('send_date_before', request.send_date_before);
      url += '&send_date_before=' + request.send_date_before;
    }
    Utils.log(url);
    return this.getListWithToken<HoyoMailGetResponse>('/hoyo/mails/', params);
  }
  // 法要メール情報登録
  public addHoyoMail(hoyo_mail: HoyoMailPostRequest): Promise<HoyoMailPostResponse> {
    Utils.log(this.host + '/hoyo/mails/');
    return this.postWithToken<HoyoMailPostRequest, HoyoMailPostResponse>('/hoyo/mails/', hoyo_mail);
  }

  // イベントメール一覧取得
  public async getEventMailList(request: EventMailGetRequest): Promise<EventMailGetResponse[]> {
    // await this.refreshToken();
    let url = this.host + '/event_mails/' + '?company=' + request.company;
    let params = new HttpParams();
    params = params.set('company', String(request.company));
    if (request.send_date_after) {
      params = params.append('send_date_after', request.send_date_after);
      url += '&send_date_after=' + request.send_date_after;
    }
    if (request.send_date_before) {
      params = params.append('send_date_before', request.send_date_before);
      url += '&send_date_before=' + request.send_date_before;
    }
    Utils.log(url);
    return this.getListWithToken<EventMailGetResponse>('/event_mails/', params);
  }
  // イベントメール情報登録
  public addEventMail(event_mail: EventMailPostRequest): Promise<EventMailPostResponse> {
    Utils.log(this.host + '/event_mails/');
    return this.postWithToken<EventMailPostRequest, EventMailPostResponse>('/event_mails/', event_mail);
  }

  // FAQ一覧取得
  public async getFaqList(request: FaqGetRequest): Promise<FaqGetResponse[]> {
    let url = this.host + '/faqs/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<FaqGetResponse>('/faqs/', params);
  }

  // FAQ登録
  public addFaq(faq: FaqPostRequest): Promise<FaqPostResponse> {
    Utils.log(this.host + '/faqs/');
    return this.postWithToken<FaqPostRequest, FaqPostResponse>('/faqs/', faq);
  }

  // FAQ更新
  public updateFaq(id: number, faq: FaqPutRequest): Promise<FaqPutResponse> {
    Utils.log(this.host + '/faqs/' + id + '/');
    return this.patchWithToken<FaqPutRequest, FaqPutResponse>('/faqs/' + id + '/', faq);
  }

  // FAQ削除
  public deleteFaq(id: number): Promise<FaqDeleteResponse> {
    Utils.log(this.host + '/faqs/' + id + '/');
    return this.deleteWithToken<FaqDeleteResponse>('/faqs/' + id + '/');
  }

  // 広告一覧取得
  public async getAdvertiseList(request: AdvertiseGetRequest): Promise<AdvertiseGetResponse[]> {
    let url = this.host + '/advertises/';
    let params = new HttpParams();
    if (request.company) {
      params = params.append('company', String(request.company));
      url += '?company=' + request.company;
    }
    Utils.log(url);
    return this.getListWithToken<AdvertiseGetResponse>('/advertises/', params);
  }

  // 広告登録
  public addAdvertise(ad: AdvertisePostRequest): Promise<AdvertisePostResponse> {
    Utils.log(this.host + '/advertises/');
    return this.postWithToken<AdvertisePostRequest, AdvertisePostResponse>('/advertises/', ad);
  }

  // 広告更新
  public updateAdvertise(id: number, ad: AdvertisePutRequest): Promise<AdvertisePutResponse> {
    Utils.log(this.host + '/advertises/' + id + '/');
    return this.patchWithToken<AdvertisePutRequest, AdvertisePutResponse>('/advertises/' + id + '/', ad);
  }

  // 広告削除
  public deleteAdvertise(id: number): Promise<AdvertiseDeleteResponse> {
    Utils.log(this.host + '/advertises/' + id + '/');
    return this.deleteWithToken<AdvertiseDeleteResponse>('/advertises/' + id + '/');
  }


  // // ログアウト
  // public postLogout(logout: LogoutPostReqeust): Promise<LogoutPostReponse> {
  //   return this.postWithToken<LogoutPostReqeust, LogoutPostReponse>('/logout/', logout);
  // }

  // トークンリフレッシュ
  public async refreshToken(): Promise<any> {
    let session_name = null;
    let apiUrl = null;
    let login_url = null;
    if (window.location.pathname.includes('/foc')) {
      session_name = 'staff_login_info';
      apiUrl = this.host + '/staffs/token/refresh/';
      login_url = 'foc/login';
    } else if (window.location.pathname.includes('/soke')) {
      session_name = 'soke_login_info';
      apiUrl = this.host + '/seko/token/refresh/';
      login_url = 'soke/login';

    }
    if (!session_name) {
      return Promise.resolve();
    }
    let login_info = this.sessionSvc.get(session_name);
    if (!login_info || !login_info.token) {
      return Promise.resolve();
    }
    Utils.log(apiUrl);
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = JSON.stringify({ refresh: login_info.token.refresh });

    return this.http.post<any>(apiUrl, body, { headers: this.headers })
      .toPromise()
      .then((res) => {
        if (res.access) {
          login_info = this.sessionSvc.get(session_name);
          login_info.token.access = res.access;
          this.sessionSvc.save(session_name, login_info);
        }
        return res;
      })
      .catch((err) => {
        if (err.status === 401) {
          // this.checkErrorType(err);
          this.sessionSvc.clearAll();
          this.router.navigate([login_url]);
          const message = {
            type: 'error',
            title: 'セッションの有効期限が切れました。ログインし直してください。'
          };
          this.loaderSvc.dismiss();
          setTimeout(() => {
            this.sessionSvc.save('message', message);
          }, 400);
        }
        return Promise.reject(err.error || 'error');
      });
    /*
    let err = false;
    if (login_info.token) {
      Utils.log(this.host + '/staffs/token/refresh/');
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const body = JSON.stringify({ refresh: login_info.token.refresh });
      try {
        const result = await this.http.post<any>(this.host + '/staffs/token/refresh/', body, { headers: this.headers }).toPromise();
        if (result.access) {
          login_info = this.getLoginInfo();
          login_info.token.access = result.access;
          this.sessionSvc.save('staff_login_info', login_info);
        }
      } catch {
        err = true;
      }
    }
    return new Promise((resolve, reject) => {
      if (err) {
        // トークンの期限切れなどでリフレッシュが失敗した時、セッションストレージのトークンを破棄する。
        this.sessionSvc.clearAll();
        this.router.navigate(['foc/login']);
        const message = {
          type: 'error',
          title: 'セッションの有効期限が切れました。ログインし直してください。'
        };
        this.loaderSvc.dismiss();
        setTimeout(() => {
          this.sessionSvc.save('message', message);
        }, 200);
        return Promise.reject(err || 'error');
      } else {
        resolve();
      }
    });
    */
  }

  public checkErrorType(err) {
    Utils.log(err);
    const message = {
      type: 'error',
      title: 'サーバー処理が失敗しました。',
      msg: ''
    };
    if (err) {
      if (err.status === 401 && !err.url.indexOf('login')) {
        this.sessionSvc.clearAll();
        this.router.navigate(['foc/login']);
        message.title = 'セッションの有効期限が切れました。ログインし直してください。';
      } else if (err.status === 404) {
        message.title = '該当情報が見つかりません。';
      } else if (err.error && err.error.detail) {
        message.msg = err.error.detail;
      }
    }
    this.loaderSvc.dismiss();
    // if (!err.url || !err.url.contains('login')) {
    this.sessionSvc.save('message', message);
    // }
    return err;
  }
  private getLoginInfo() {
    if (window.location.pathname.includes('/foc')) {
      return this.sessionSvc.get('staff_login_info');
    } else if (window.location.pathname.includes('/soke')) {
      return this.sessionSvc.get('soke_login_info');
    }
    return null;
  }
}
